/* eslint-disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run scripts/generate-types to regenerate this file.
 */

/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "environmentTag".
 */
export type EnvironmentTag =
  | 'production'
  | 'staging'
  | 'demo'
  | 'dev'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "email".
 */
export type Email = string
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "dmsFilterOperator".
 */
export type DmsFilterOperator = 'lte' | 'ste' | 'gte' | 'eq' | 'noteq' | 'between' | 'notbetween' | 'null' | 'notnunll'

export interface SpotEntity extends PlaceEntity {
  core_yms_spot?: {
    name: string
    facility: Edge
    isActive?: boolean
    group?: string
    capacity?: number
    driverInstructions?: string
    bulkUpdateInstructions?: boolean
    locationType?: LocationType
    open?: number
    status: Status
    currentTrailers?: {
      trailer?: Edge
    }[]
    activeYardMoves?: {
      yardMove?: Edge
    }[]
    notes?: string
    geolocation?: Geolocation
  }
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "edge".
 */
export interface Edge {
  entityId: string
  displayName?: string
}
export interface Geolocation {
  latitude?: number
  longitude?: number
  heading?: number
}
export interface PlaceEntity extends Entity {
  core_geography_place?: {
    additionalIdentifiers?: LabeledIdentifier[]
    sourceId?: string
    sourceSystem?: string
  }
}
/**
 * This interface was referenced by `PlaceEntity`'s JSON-Schema
 * via the `definition` "labeledIdentifier".
 */
export interface LabeledIdentifier {
  label?: string
  value?: string
}
export interface Entity {
  /**
   * The unique identifier
   */
  uniqueId: string
  mixins: {
    active?: Edge[]
    inactive?: Edge[]
  }
  owner?: Owner
  status?: Status1
  createdBy?: Edge
  creationDate?: string
  modifiedBy?: Edge
  modifiedDate?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "owner".
 */
export interface Owner {
  user?: Edge
  firm?: Edge
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "status".
 */
export interface Status1 {
  state?: 'new' | 'pending' | 'idle' | 'busy' | 'error' | 'deleted'
  progress?: {
    percent?: number
    message?: string
  }
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "ach".
 */
export interface Ach {
  accountNumber?: string
  routingNumber?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "boundingBox".
 */
export interface BoundingBox {
  left?: number
  top?: number
  width?: number
  height?: number
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "itemReference".
 */
export interface ItemReference {
  itemId: string
  arrayPath?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "phoneNumber".
 */
export interface PhoneNumber {
  phone?: string
  extension?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "labeledPhoneNumber".
 */
export interface LabeledPhoneNumber {
  value?: PhoneNumber
  label?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "labeledEmail".
 */
export interface LabeledEmail {
  value?: Email
  label?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "labeledLocation".
 */
export interface LabeledLocation {
  value?: Edge
  label?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "quantity".
 */
export interface Quantity {
  unit: string
  value: number
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "currency".
 */
export interface Currency {
  unit: string
  value: number
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "dimensions".
 */
export interface Dimensions {
  width: Measurement
  height: Measurement
  length: Measurement
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "measurement".
 */
export interface Measurement {
  unit: 'Feet' | 'Inches'
  value: number
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "secretString".
 */
export interface SecretString {
  secret?: string
  versionId?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "temperature".
 */
export interface Temperature {
  unit: 'Celsius' | 'Fahrenheit'
  value: number
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "temperatureRange".
 */
export interface TemperatureRange {
  min?: Temperature
  max?: Temperature
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "weight".
 */
export interface Weight {
  unit: 'Kilograms' | 'Tons' | 'Unknown' | 'Pounds'
  value: number
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "dmsRule".
 */
export interface DmsRule {
  'rule-type'?: string
  'rule-id'?: string
  'rule-name'?: string
  'rule-action'?: string
  'rule-target'?: string
  value?: string
  'object-locator'?: DmsObjectLocator
  filters?: DmsFilter[]
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "dmsObjectLocator".
 */
export interface DmsObjectLocator {
  'schema-name'?: string
  'table-name'?: string
  'column-name'?: string
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "dmsFilter".
 */
export interface DmsFilter {
  'filter-type'?: string
  'column-name'?: string
  'filter-conditions'?: DmsFilterCondition[]
}
/**
 * This interface was referenced by `Entity`'s JSON-Schema
 * via the `definition` "dmsFilterCondition".
 */
export interface DmsFilterCondition {
  'filter-operator'?: DmsFilterOperator
  value?: string
}

export const enum LocationType {
  SPOT = 'Spot',
  DOOR = 'Door',
  AREA = 'Area'
}
export const enum Status {
  FULL = 'Fully Occupied',
  PARTIAL = 'Partially Occupied',
  OPEN = 'Open'
}
