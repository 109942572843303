import apis from 'browser/app/models/apis'
import { browserHistory } from 'browser/history'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

import { SchemaIds, SchemaUris } from 'shared-libs/models/schema'
import { INVOICE_ENTITY_TYPE, INVOICE_PATH, INVOICE_REQUIREMENTS_PATH, RB_V2_CONFIG } from 'browser/app/constants/rendition-billing'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { Modal } from 'browser/components/atomic-elements/atoms/modal'
import { Requirement } from 'browser/components/atomic-elements/atoms/table/cells/table-requirements-cell'
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import { EntityTable } from 'browser/components/atomic-elements/organisms/entity/entity-table'
import OverlayManager from 'browser/components/atomic-elements/organisms/overlay-manager/overlay-manager'
import { Formatter } from 'shared-libs/helpers/formatter'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'

import './_rendition-billing-confirmation-modal.scss'
import { ErrorModal } from '../error-modal'
import { stringifyServerError } from 'shared-libs/models/utils'

const DOCUMENTS_CATEGORY = 'document'
const PREFERENCES_CATEGORY = 'preference'
const INVOICING_BASE_VIEW_PATH = '/view/11cc0d6c-5907-4c82-85ec-6998a537a709'
const INVOICING_SENT_VIEW_PATH = `${INVOICING_BASE_VIEW_PATH}/view/ec5fe5f4-542b-4f3c-93a4-bdd9d600c33d`
const { INVOICE_NUMBER_PATH } = RB_V2_CONFIG

const TABLE_COLUMNS = [
  {
    isVisible: true,
    label: 'Invoice Number',
    path: INVOICE_NUMBER_PATH,
    width: 150
  },
  {
    isVisible: true,
    label: 'Customer',
    path: `${INVOICE_PATH}.billTo`,
    width: 150
  },
  {
    isVisible: true,
    label: 'Revenue',
    path: `${INVOICE_PATH}.totalRevenue.value`,
    width: 100
  },
  {
    isVisible: true,
    label: 'Method',
    path: `${INVOICE_PATH}.billTo.denormalizedProperties["receivable.invoicePreference"]`,
    width: 100
  }
]

const isRequirmentSatisfied = (entity, requirementCategory) => {
  const requirements = _.get(entity, INVOICE_REQUIREMENTS_PATH, [])
  return _.reduce(requirements, (result: boolean, requirement: Requirement) => {
    const { category, satisfied } = requirement
    return requirementCategory === category ? result && satisfied : result
  }, true) // if no requirement exists for that invoice entity, then it is satisfied by default
}

const isChildInvoice = (entity) => {
  const parentInvoice = _.get(entity, 'core_accounting_accountsReceivable_salesInvoice.consolidateSalesInvoice', null)

  return !_.isNil(parentInvoice)

}

/**
 * @prop onClose - callback executed when modal is closed. Used by modal overlay manager.
 * @prop selection - array of invoice entities selected by the user
 * @prop dataSet - the entire EntityDataSource of the displayed invoice entities
 */
interface IRenditionBillingConfirmationModalProps extends IBaseProps {
  onClose: () => any
  selection: any[]
  dataSet: EntityDataSource
}

interface IRenditionBillingConfirmationModalState {
  readyToInvoice: any[]
  missingDocs: any[]
  missingPreferences: any[]
  childInvoices: any[]
  dataSet: EntityDataSource
  isLoading: boolean
}
/**
 * Modal displayed that shows what invoices will be billed
 *
 * @props IRenditionBillingConfirmationModalProps
 */
export class RenditionBillingConfirmationModal extends React.Component<IRenditionBillingConfirmationModalProps, IRenditionBillingConfirmationModalState> {
  private entitySchema: any
  private store: any
  private billAllReady: boolean // bill all ready is clicked
  private isDispose: boolean // flag to stop outstanding promises
  constructor(props) {
    super(props)

    this.billAllReady = false
    this.isDispose = false

    const { dataSet, selection } = props
    let readyToInvoiceDataSet = dataSet

    if (_.isEmpty(selection)) {
      this.billAllReady = true
      readyToInvoiceDataSet = dataSet.clone()
      this.configReadyToInvoiceQuery(readyToInvoiceDataSet)
    }

    this.state = {
      readyToInvoice: [],
      missingDocs: [],
      missingPreferences: [],
      childInvoices: [],
      dataSet: readyToInvoiceDataSet,
      isLoading: this.billAllReady ? true : false
    }
    this.store = apis.getStore()
    this.entitySchema = this.store.getRecord(INVOICE_ENTITY_TYPE)

  }

  public componentDidMount() {
    this.sortInvoicesByStatus()

    if (this.billAllReady) {
      this.getAllReadyToInvoices().then(() => {
        this.setState( { isLoading: false})
        this.sortInvoicesByStatus()
      }).catch(e => {
        this.setState( { isLoading: false})
      })
    }
  }

  public componentWillUnmount() {
    if (this.billAllReady) {
      const { dataSet } = this.state
      dataSet.dispose()
      this.isDispose = true
    }
  }

  public static open(props) {
    return OverlayManager.openOverlayElement(
      <Modal>
        <RenditionBillingConfirmationModal {...props} />
      </Modal>,
    )
  }

  public render() {
    const { onClose, selection } = this.props
    const { isLoading } = this.state
    let content = (
      <div>
        No invoices can be billed with your current selection
      </div>
    )

    // selection will be empty if if modal is launched from the 'Bill All Ready' button
    if (isLoading || this.state.readyToInvoice.length > 0 || !_.isEmpty(selection)) {
      content = (
        <div>
          {this.renderLoadingStatus()}
          {this.renderReadyToInvoice()}
          {this.renderMissingDocs()}
          {this.renderMissingPreferences()}
        </div>
      )
    }

    return (
      <>
        <div className='c-modalHeader'>
          <h4 className='c-modal-title'>Invoicing Summary</h4>
        </div>
        <div>
          {this.renderSummaryHeader()}
        </div>
        <div className='c-modalBody collapse u-bumperLeft--lg u-bumperRight--lg u-bumperTop'>
          <div>
            {content}
          </div>
        </div>
        <Footer
          className='u-bumperTop'
          isVisible={true}
          cancelButtonText='Close'
          onCancelButtonClick={onClose}
          primaryButtonText='Start Rendition Billing'
          onPrimaryButtonClick={this.handleStartRenditionBillingPressed}
        />
      </>
    )
  }

  private renderSummaryHeader = () => {
    const { readyToInvoice, missingPreferences, missingDocs, childInvoices } = this.state
    const { selection } = this.props
    const total = selection.length
    const nonbillable = missingPreferences.length + childInvoices.length
    const billable = total - nonbillable

    const forceBill = missingDocs.length
    const invoiceReady = readyToInvoice.length

    const emailInvoice = _.filter(selection, ({data}) => _.get(data, `${INVOICE_PATH}.billTo.denormalizedProperties["receivable.invoicePreference"]`) === 'Email')
    const mailInvoice =  _.filter(selection, ({data}) => {
      // in theory, it is best to fetch the customer and check the invoice preference, but that is costly, so
      // indirectly use in denormalized or requirement
       return _.get(data, `${INVOICE_PATH}.billTo.denormalizedProperties["receivable.invoicePreference"]`) === 'Mail' ||
          _.find(_.get(data, `${INVOICE_PATH}.readyToInvoice.requirements`, []), { label: 'Primary Address', satisfied: true})
    })

    const email = emailInvoice.length
    const printPdf = mailInvoice.length

    return this.renderSummaryHeaderItems({
      total, billable, nonbillable, invoiceReady, forceBill, email, printPdf
    })
  }

  private renderReadyToInvoice = () => {
    const readyToInvoiceLength = this.state.readyToInvoice.length
    return (
      <>
        {readyToInvoiceLength > 0 && (
          <div className='selected-docs-text-container'>
            <div className='invoice-ready-text'>{readyToInvoiceLength}</div>
            {` selected invoice${readyToInvoiceLength === 1 ? ' is' : 's are'} ready to invoice`}
          </div>
        )}
      </>
    )
  }

  private renderMissingDocs = () => {
    const { missingDocs } = this.state
    const missingDocsLength = missingDocs.length
    return (
      <>
        {missingDocsLength > 0 && (
          <>
            <div className='selected-docs-text-container'>
              <div className='invoice-forcebill-text'>{missingDocsLength}</div>
              {` selected invoice${missingDocsLength === 1 ? ' is' : 's are'} missing required documents and will be billed without them`}
            </div>
            <div className='modal-table-container'>
              <EntityTable
                columns={TABLE_COLUMNS}
                entitySchema={this.entitySchema}
                isConfigurable={false}
                onRowClick={null}
                onRowSelect={null}
                rows={missingDocs}
                width={550}
              />
            </div>
          </>
        )}
      </>
    )
  }

  private renderMissingPreferences = () => {
    const { missingPreferences } = this.state
    const missingPreferencesLength = missingPreferences.length
    return (
      <>
        {missingPreferencesLength > 0 && (
          <>
            <div className='selected-docs-text-container'>
              <div className='invoice-nonbillable-text'>{missingPreferencesLength}</div>
              {` selected invoice${missingPreferencesLength === 1 ? ' has' : 's have'} a customer with no invoice preferences set, and will not be billed`}
            </div>
            <div className='modal-table-container'>
              <EntityTable
                columns={TABLE_COLUMNS}
                entitySchema={this.entitySchema}
                isConfigurable={false}
                onRowClick={null}
                onRowSelect={null}
                rows={missingPreferences}
                width={550}
              />
            </div>
          </>
        )}
      </>
    )
  }

  private renderLoadingStatus = () => {
    const { isLoading, dataSet } = this.state
    const percentage = Math.round(_.get(dataSet, 'entities.length', 0) / _.get(dataSet, 'count', 1) * 100)
    return (
      <>
        {isLoading && (<LoadingSpinner label={`${percentage}%`}/>)}
      </>
    )
  }

  /**
   * Add additional filters to query for sales invoices that ready
   * for invoice
   */
  private configReadyToInvoiceQuery = (dataSet: EntityDataSource) => {
    const filters = dataSet.filters

    // filter for documents and invoice preferences
    filters.push({
      type: 'match',
      path: 'core_accounting_accountsReceivable_salesInvoice.readyToInvoice.status.hasRequiredDocuments',
      value: true
    })

    filters.push({
      type: 'match',
      path: 'core_accounting_accountsReceivable_salesInvoice.readyToInvoice.status.hasRequiredPreferences',
      value: true
    })

    dataSet.setFilters(filters)
  }

  private async awaitTillFinishLoading(dataSet: EntityDataSource) {
    // wait for status is ready via polling
    while (dataSet.isLoading) {
      await new Promise((resolve, reject) => setTimeout(resolve, 100))
    }
  }

  /**
   *  Fetch all sale invoices that are ready to invoice
   */
  private async getAllReadyToInvoices() {
    const { dataSet } = this.state

    // send out the query
    dataSet.find()

    // need to wait for this query to be finished before fetch the next batch
    await this.awaitTillFinishLoading(dataSet)

    while (dataSet.hasNext() && !this.isDispose) {
      // get one batch at a time
      await dataSet.handleLoadNextPage()
      // refresh the current calculation
      this.sortInvoicesByStatus()
    }
  }



  private sortInvoicesByStatus = () => {
    const { selection } = this.props
    const { dataSet } = this.state
    const readyToInvoice = []
    const missingDocs = []
    const missingPreferences = []
    const childInvoices = []

    // If no invoices are selected, bill all readyToInvoice ('Bill All Ready' pressed)
    if (this.billAllReady) {
      _.map(dataSet.collection.entities, (entity) => {
        if (!isChildInvoice(entity)
            && isRequirmentSatisfied(entity, DOCUMENTS_CATEGORY)
            && isRequirmentSatisfied(entity, PREFERENCES_CATEGORY)) {
          readyToInvoice.push(entity)
        }
      })
    } else {
       _.map(selection, (entity) => {
         if (isChildInvoice(entity)) {
           childInvoices.push(entity)
         } else if (!isRequirmentSatisfied(entity.data, PREFERENCES_CATEGORY)) {
          missingPreferences.push(entity)
        } else if (!isRequirmentSatisfied(entity.data, DOCUMENTS_CATEGORY)) {
          missingDocs.push(entity)
        } else {
          readyToInvoice.push(entity)
        }
      })
    }

    this.setState({ readyToInvoice, missingDocs, missingPreferences, childInvoices })
  }

  private handleStartRenditionBillingPressed = () => {
    const { onClose } = this.props
    const { missingDocs, readyToInvoice } = this.state
    const emailSalesInvoices = []
    const mailSalesInvoices = []
    const entityIds = _.map([...readyToInvoice, ...missingDocs], (entity) => {
      const invoiceEntity = entity.data || entity
      // Set status to Submitted
      const entityContent = invoiceEntity.content
      const isMailInvoice = _.get(invoiceEntity, `${INVOICE_PATH}.billTo.denormalizedProperties["receivable.invoicePreference"]`, 'Email') === 'Mail' ||
          _.find(_.get(invoiceEntity, `${INVOICE_PATH}.readyToInvoice.requirements`, []), { label: 'Primary Address', satisfied: true})

      _.set(entityContent, `${INVOICE_PATH}.stage`, 'Submitted')
      _.set(entityContent, `${INVOICE_PATH}.submittedDate`, moment().toISOString())
      invoiceEntity.setContent(entityContent)

      if (isMailInvoice) {
        mailSalesInvoices.push(invoiceEntity)
      } else  {
        emailSalesInvoices.push(invoiceEntity)
      }
      return { entityId: invoiceEntity.uniqueId }
    })

    const billingInProgressMsg = () => {
      return (<div>Billing in Progress..</div>)

    }

    const printedInvoiceMsg = () => {
      return (<div><br/>Any printed invoices will be made available as a PDF here and within the invoice’s activity feed after billing run is completed</div>)

    }

    const invoiceRequests = emailSalesInvoices.map(entity => {
      return entity.save()
    })

    if (!_.isEmpty(mailSalesInvoices)) {
      const mailRequest = this.mailSubmissionTask(mailSalesInvoices)
      invoiceRequests.push(mailRequest.save())
    }

    const toastKey = Toast.show({
      message: (
        <div>
          {billingInProgressMsg()}
          {!_.isEmpty(mailSalesInvoices) && printedInvoiceMsg()}
        </div>
      ),
      position: Position.BOTTOM_RIGHT,
      timeout: 0,
    })

    Promise.all(invoiceRequests)
    .then((result) =>  {
      // check if the result has a submission task
      const submissionTask = _.find(result, (entity) => this.isSubmissionTask(entity))
      return _.isNil(submissionTask) ? Promise.resolve({}) : submissionTask.waitUntilIdle()
    }).then(result => {
      const combinedPdf = _.get(result, 'core_accounting_accountsReceivable_invoiceSubmissionTask.combinedPdf', '')
      if (!_.isEmpty(combinedPdf)) {
        const createdDate = _.get(result, 'creationDate', '')
        const modifiedDate = _.get(result, 'modifiedDate', '')
        Toast.update(toastKey, {
          message: (
            <div>
              <div>
                Billing job initiated on {Formatter.formatDateTime(createdDate)} completed<br/><br/>
                Invoices to be mailed can be printed via the following file:<br/>
              </div>
              <div>
                <a href={combinedPdf} target='_blank' rel='noopener noreferrer' download={true} >
                  {combinedPdf}
                </a>
              </div>
            </div>
          ),
          onDismiss: this.handleOnRedirect,
          position: Position.BOTTOM_RIGHT,
          timeout: 10000,
        })
      } else {
        Toast.update(toastKey, {
          message: (
            <div><a onClick={this.handleOnRedirect}>Billing Complete</a></div>
          ),
          position: Position.BOTTOM_RIGHT,
          timeout: 5000,
        })
        this.handleOnRedirect()
      }
      onClose()
    }).catch((e: any) => {
      ErrorModal.open({
        errorText: `We couldn't process your invoice request. The server returned the following error: ${stringifyServerError(e)}`,
        errorTitle: 'Unable to invoice',
        onClose: () => {
          // close current 'Billing in progress toast'
          Toast.dismiss(toastKey)

          // close current modal
          onClose()
        }
      })
      console.error('Unable to invoice', e)
    })
  }

  private isSubmissionTask = (entity) => {
    return _.some(entity.activeMixins, { entityId: SchemaIds.INVOICE_SUBMISSION_TASK})
  }

  private mailSubmissionTask = (mailEntities) => {
    const mailEdges = _.map(mailEntities, (entity) => {
      const entityId = entity.uniqueId
      return { entityId }
    })
    const submissionSchema = this.store.getRecord(SchemaUris.INVOICE_SUBMISSION_TASK)
    return this.store.createRecord(submissionSchema, {
      core_accounting_accountsReceivable_invoiceSubmissionTask: {
        invoices: mailEdges
      }
    })
  }

  private handleOnRedirect = () => {
    if (window.location.pathname.includes(INVOICING_BASE_VIEW_PATH)) {
      window.location.reload()
    } else {
      browserHistory.push({
        pathname: INVOICING_SENT_VIEW_PATH,
      })
    }
  }

  private renderSummaryHeaderItems = (summary) => {
    const { total,
      billable = '-', nonbillable = '-',
      invoiceReady = '-', forceBill = '-',
      email = '-', printPdf = '-' } = summary

    const items = [
      [
        [{ 'text': total, className: 'invoice-header-total' },
         { 'text': 'Selected Invoices', className: 'invoice-header-subtitle'} ]
      ],
      [
        [{ 'text': billable, className: 'invoice-header-billable' },
         { 'text': 'Billable', className: 'invoice-header-subtitle'}],
        [{ 'text': nonbillable, className: 'invoice-header-nonbillable' },
         { 'text': 'Non-billable', className: 'invoice-header-subtitle'}]
      ],
      [
        [{ 'text': invoiceReady, className: 'invoice-header-invoiceready' },
         { 'text': 'Invoice Ready', className: 'invoice-header-subtitle'}],
        [{ 'text': forceBill, className: 'invoice-header-invoiceforce' },
         { 'text': 'Force Bill', className: 'invoice-header-subtitle'}]
      ],
      [
        [{ 'text': email, className: 'invoice-header-invoiceemail' },
         { 'text': 'Email', className: 'invoice-header-subtitle'}],
        [{ 'text': printPdf, className: 'invoice-header-invoiceprint' },
         { 'text': 'Print PDF', className: 'invoice-header-subtitle' } ]
      ]
    ]
    return (
      <div className='invoice-summary-container'>
        {_.map(items, this.renderHeaderGroupItems)}
      </div>
    )
  }

  private renderHeaderGroupItems = (item, index) => {
    return (
        <div className='invoice-summary-header-combo'>
          {_.map(item, this.renderItem)}
        </div>
    )
  }

  private renderItem = (lines, index) => {
    return (
      <div style={{padding: '5px 5px 5px 5px'}}>
        {_.map(lines, this.renderText)}
      </div>
    )
  }

  private renderText = (line) => {
    const { className, text } = line
    return (
      <div className={`${className}`}>
        {text}
      </div>
    )

  }
}
