import React, { useContext, useState } from 'react'
import _ from 'lodash'

import '../../styles/_control.scss'
import apis from 'browser/app/models/apis'
import MobileComponents from '../index'
import EntitySelect, { IEntitySelectProps } from 'browser/components/atomic-elements/atoms/select/entity-select'
import { ViewRenderer } from 'shared-libs/components/view/renderer'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { CSSObject } from '@emotion/serialize'


/**
 * @uiComponent
 */
interface IMobileEntitySearchProps {
  query: any
  lazyItemUiSchema?: any
  preloadResults: boolean
}

export const MobileEntitySearchField = (props: IMobileEntitySearchProps & IEntitySelectProps) => {
  const { query = {}, lazyItemUiSchema, preloadResults } = props
  const { entityType, queryPath, queryPaths, filters } = query
  const context = useContext(ComponentsContext);
  const [selectedItem, setSelectedItem] = useState(null)

  const renderOption = (option) => {
    const handleClick = () => {
      option.selectOption(option.data)
      setSelectedItem(option.data);
    }
    return (
      <ViewRenderer
        schema={lazyItemUiSchema ?? defaultItemTemplate}
        state={{
          ...context,
          ...props,
          entity: option.data,
          item: option.data,
          selectedOption: selectedItem,
          selectOption: handleClick,
        }}
        componentsMap={MobileComponents}
        api={apis}
      />
    );
  }

  return (
    <EntitySelect
      entityType={entityType}
      queryPath={queryPath}
      queryPaths={queryPaths}
      filters={filters}
      returnValueAsEdge={true}
      preloadOptions={preloadResults}
      shouldPreloadEntity={false}
      showInlinePreview={false}
      showLinkIcon={false}
      isCreatable={false}
      optionRenderer={renderOption}
      inputClassName='mobile-control'
      styles={{
        valueContainer: (base): CSSObject => ({
          ...base,
          height: '44pt',
        }),
      }}
      {...props}
    />)
}

const defaultItemTemplate = {
  type: 'ui:view',
  children: [
    {
      type: 'ui:view',
      mapStateToProps: {
        onClick: 'selectOption',
      },
      children: [
        {
          children: [
            {
              numberOfLines: 1,
              style: {
                color: 'rgba(35, 34, 35, 1)',
                flex: 1,
              },
              text: {
                type: 'expression',
                value: 'entity.displayName ?? entity.label',
              },
              type: 'ui:text',
            },
          ],
          style: {
            paddingLeft: 18,
            paddingRight: 18,
            marginTop: "1.2rem",
            marginBottom: "1.2rem",
            backgroundColor: {
              type: "expression",
              value: "selectedItem === entity ? '#e1e1e1' : '#fff'"
            },
            cursor: 'pointer'
          },
          type: 'ui:view',
        },
      ],
    },
  ],
}