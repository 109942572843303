import { Hotkey, Hotkeys, HotkeysTarget, Icon } from '@blueprintjs/core'
import { browserHistory } from 'browser/history'
import classNames from 'classnames'
import _ from 'lodash'
import queryString from 'query-string'
import React from 'react'
import Autosuggest from 'react-autosuggest'

import { Logger } from 'browser/apis/logging'
import apis from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { Query } from 'shared-libs/models/query'

import { IconNames } from '@blueprintjs/icons'
import { Settings } from 'browser/app/models/settings'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import 'browser/components/atomic-elements/organisms/top-navigation-bar/search-bar/_search-input.scss'
import 'browser/components/atomic-elements/organisms/top-navigation-bar/search-bar/_search-result.scss'
import 'browser/components/atomic-elements/organisms/top-navigation-bar/search-bar/_search-suggestion.scss'
import { getFacilityFilters } from 'browser/app/pages/app/search'
import { DEBOUNCE_TIMEOUT } from 'browser/app/utils/utils'

const NUM_SEARCH_RESULTS_TO_SHOW = 8

interface ISearchBarProps extends IBaseProps {
  location: object
  settings: Settings
}

interface ISearchBarStates {
  searchQuery: string
  searchResults: any[]
}

@HotkeysTarget
class SearchBar extends React.Component<ISearchBarProps, ISearchBarStates, never> {

  private entityStore: any
  private lastQuery: any
  private query: Query
  private removeListener: () => void
  private searchInput: Autosuggest

  constructor(props) {
    super(props)
    this.lastQuery = null
    this.state = {
      searchQuery: '',
      searchResults: [],
    }
    this.queryEntities = _.debounce(this.queryEntities, DEBOUNCE_TIMEOUT)
    this.entityStore = apis.getStore()
  }

  public componentDidMount() {
    this.removeListener = browserHistory.listen(this.handleLocationChange)

    const { location, settings } = this.props
    this.handleLocationChange(location)
    this.query = new Query(apis)
      .setFilters(getFacilityFilters())
      .setEntityType('/1.0/entities/metadata/document.json')
      .setHighlightingEnabled(true)
      .setSize(NUM_SEARCH_RESULTS_TO_SHOW)
    this.query.addEntityType('/1.0/entities/metadata/core_storyboard_execution.json')
    if (settings.isBrokerTMSApplication()) {
      this.query.addEntityType('/1.0/entities/metadata/brokerOrder.json')
      this.query.addEntityType('/1.0/entities/metadata/core_fulfilment_salesOrder.json')
    }
    if (settings.isCarrierTMSAppliction()) {
      this.query.addEntityType('/1.0/entities/metadata/carrierOrder.json')
    }
    if (settings.isRenditionBillingEnabled) {
      this.query.addEntityType('/1.0/entities/metadata/rbInvoice.json')
    }
    if (settings.isRenditionBillingV2Enabled || settings.isDriverAppEnabled) {
      this.query.addEntityType('/1.0/entities/metadata/core_fulfilment_trip.json')
      this.query.addEntityType('/1.0/entities/metadata/core_fulfilment_salesOrder.json')
      this.query.addEntityType('/1.0/entities/metadata/core_accounting_accountsReceivable_salesInvoice.json')
    }
    if (settings.isYmsEnabled) {
      this.query.addEntityType('/1.0/entities/metadata/core_yms_spot.json')
      this.query.addEntityType('/1.0/entities/metadata/core_yms_trailer.json')
      this.query.addEntityType('/1.0/entities/metadata/location.json')
      this.query.addEntityType('/1.0/entities/metadata/core_calendar_appointment.json')
      this.query.addEntityType('/1.0/entities/metadata/core_yms_shipment.json')
    }
  }

  public componentWillUnmount() {
    this.removeListener()
  }

  public render() {
    const { searchQuery } = this.state
    return (
      <div
        className={classNames('c-searchInput', this.props.className, {
          'c-searchInput--hasValue': !_.isEmpty(searchQuery),
        })}
      >
        <Icon
          className='c-searchInput-icon'
          icon={IconNames.SEARCH}
        />
        <Autosuggest
          getSuggestionValue={this.handleGetSuggestionValue}
          inputProps={{
            onChange: this.handleSearchQueryChange,
            onKeyPress: this.handleOnKeyPress,
            placeholder: 'Search',
            value: searchQuery,
          }}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          renderSuggestion={this.renderSuggestion}
          ref={this.handleSetSearchInput}
          suggestions={this.state.searchResults}
        />
      </div>
    )
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo='/'
          label='Search'
          onKeyDown={this.handleFocusSearchInput}
        />
      </Hotkeys>
    )
  }

  private queryEntities(searchQuery) {
    this.lastQuery?.cancel()

    this.query.setQuery(searchQuery.trim())
    const options = this.query.build()
    this.lastQuery = this.entityStore.queryRecords(options, true)
    this.lastQuery.then((result) => {
      this.setState({
        searchResults: result.children,
      })
    }).finally((exception) => {
      this.lastQuery = null
    })
  }

  /****************************************************************************/
  /* Callbacks
  /****************************************************************************/

  private handleFocusSearchInput = (event: KeyboardEvent) => {
    event.preventDefault()
    this.searchInput.input.focus()
  }

  private handleLocationChange = (location) => {
    const { query } = queryString.parse(location.search)
    const searchQuery = _.isEmpty(query) ? '' : query
    this.setState({ searchQuery })
  }

  private handleGetSuggestionValue = (entity) => {
    return entity.displayName
  }

  private handleOnKeyPress = (event) => {
    let { searchQuery } = this.state
    if (event.key === 'Enter' && !_.isEmpty(searchQuery)) {
      Logger.logEvent('Searched', { searchQuery })
      searchQuery = encodeURIComponent(searchQuery)
      browserHistory.push({
        pathname: '/search',
        search: `?query=${searchQuery}`,
      })
    }
  }

  private handleSearchQueryChange = (event, { newValue, method }) => {
    this.setState({ searchQuery: newValue })
  }

  private handleSetSearchInput = (ref: Autosuggest) => {
    this.searchInput = ref
  }

  private handleSuggestionSelected = (event, { suggestion, suggestionValue, sectionIndex, method }) => {
    const { searchQuery } = this.state
    event.preventDefault()
    Logger.logEvent('Searched', { searchQuery })
    browserHistory.push({ pathname: `/entity/${suggestion.uniqueId}` })
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  private handleSuggestionsFetchRequested = ({ value }) => {
    this.queryEntities(value)
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  private handleSuggestionsClearRequested = () => {
    this.setState({
      searchResults: [],
    })
  }

  private renderSuggestion(entity) {
    return (
      <EntityRenderer
        actions={this}
        componentsMap={ComponentsMap}
        state={{ isSelected: false }}
        value={entity}
        uiSchemaPath='uiSchema.web.searchSuggestion'
      />
    )
  }
}

export default React.forwardRef((props: ISearchBarProps, ref: React.Ref<SearchBar>) => (
  <AppNavigatorContext.Consumer>
    {({settings}) => <SearchBar {...props} settings={settings} ref={ref} />}
  </AppNavigatorContext.Consumer>
))
