import apis from 'browser/app/models/apis'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { FormGroup } from 'browser/components/atomic-elements/atoms/form-group/form-group'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { TextMaskInput } from 'browser/components/atomic-elements/atoms/input/text-mask-input'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { ZipcodeQuery } from './zipcode-query'
import { DEBOUNCE_TIMEOUT } from 'browser/app/utils/utils'

const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/]

export interface IGeolocationFilterZipcodeTabPanelProps extends IBaseProps {
  activeFilters: any[]
  entitySchema: object
  onChange: (value: any) => void
  onResize?: () => void
  value: any
}

interface IGeolocationFilterZipcodeTabPanelState {
  isSearching: boolean
  numCities?: number
  numLoads?: number
}

export class GeolocationFilterZipcodeTabPanel extends
  React.Component<IGeolocationFilterZipcodeTabPanelProps, IGeolocationFilterZipcodeTabPanelState> {

  private filterKey: string
  private zipcodeQuery: ZipcodeQuery

  constructor(props) {
    super(props)
    const { activeFilters, entitySchema, value } = props
    this.filterKey = value.key || value.path
    this.state = {
      isSearching: false,
    }
    this.zipcodeQuery = new ZipcodeQuery(
      apis, entitySchema.id, activeFilters, this.filterKey)
    this.performSearch = _.debounce(this.performSearch.bind(this), DEBOUNCE_TIMEOUT)
  }

  public componentWillUnmount() {
    this.zipcodeQuery?.cancel()
  }

  public render() {
    const zipCode = this.getSelection()
    return (
      <React.Fragment>
        <FormGroup>
          <TextMaskInput
            inputClassName='c-geoRegionInput-zipcode'
            guide={false}
            keepCharPositions={false}
            mask={zipCodeMask}
            onChange={this.handleZipCodeChange}
            placeholder='Enter zipcode range'
            value={zipCode}
          />
        </FormGroup>
        {this.renderBody()}
      </React.Fragment>
    )
  }

  private renderBody() {
    const { isSearching, numCities, numLoads } = this.state
    const zipCode = this.getSelection()
    if (isSearching) {
      return (
        <div className='c-multiSelectFilter-loading u-borderBottom'>
          <LoadingSpinner size='xs' />
        </div>
      )
    }

    if (_.isEmpty(zipCode)) {
      return
    } else if (numLoads === 0) {
      return (
        <div className='pv1 u-borderBottom'>
          <div className='u-bumperLeft u-bumperRight tc'>
            <HelpBlock>No results found</HelpBlock>
          </div>
        </div>
      )
    }

    return (
      <div className='pv1 u-borderBottom'>
        <div className='u-bumperLeft u-bumperRight'>
          <HelpBlock>
            Number of cities: {numCities}
          </HelpBlock>
          <HelpBlock>
            Number of loads: {numLoads}
          </HelpBlock>
        </div>
      </div>
    )
  }

  private handleZipCodeChange = (query) => {
    const { onChange, value } = this.props
    if (!_.isEmpty(query)) {
      this.setState({ isSearching: true })
      this.performSearch(query)
    }
    onChange({
      displayName: `Zipcode: ${query}`,
      key: this.filterKey,
      label: value.label,
      path: `${this.filterKey}.postalCode`,
      type: 'prefix',
      value: query,
    })
  }

  private performSearch(query) {
    this.zipcodeQuery.queryLoadCountForZipcode(query).then((result) => {
      this.setState({
        isSearching: false,
        numCities: _.get(result, 'metadata.totalChildrenCount'),
        numLoads: _.get(result, 'metadata.totalEntityMatchCount'),
      })
    })
  }

  private getSelection() {
    const { value } = this.props
    return value.type === 'prefix' ? _.get(value, 'value', '') : ''
  }

}
