import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'

import { Entity } from 'shared-libs/models/entity'
import { canEditFirmView } from 'shared-libs/models/utils'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { EntityDataSource } from 'browser/components/atomic-elements/organisms/entity/entity-data-source'
import 'browser/components/atomic-elements/organisms/view-header/_view-chooser.scss'
// tslint:disable-next-line:max-line-length
import { ViewConfigurationModal } from 'browser/components/atomic-elements/organisms/view-header/view-configuration-modal'
const vectorFirmId = '00000000-0000-0000-0000-000000000000'
import { Settings } from 'browser/app/models/settings'
import { Section } from 'browser/components/atomic-elements/atoms/section/section'
import { FormTable } from '../../atoms/form-table/form-table'
import { InputField } from '../../molecules/fields/input-field/input-field'
import { IViewContext, ViewContext } from 'browser/contexts/view-context'
import { withContext } from 'shared-libs/components/context/with-context'
import { makeViewLink } from './utils'
import { DEBOUNCE_TIMEOUT } from 'browser/app/utils/utils'

export interface IViewChooserProps extends IBaseProps {
  baseViewId?: string
  settings: Settings
  view: Entity

  viewContext?: IViewContext
}

interface IViewChooserState {
  baseView: any
  firmName: string
  firmViews: any[]
  globalViews: any[]
  personalViews: any[]
  isLoading: boolean
  isSearching: boolean
  lastQuery: string
}

@withContext(ViewContext, 'viewContext')
export class ViewChooser extends React.Component<IViewChooserProps, IViewChooserState> {
  private personalViewsDataSet: EntityDataSource
  private vectorViewsDataSet: EntityDataSource
  private firmViewsDataSet: EntityDataSource

  constructor(props) {
    super(props)
    this.state = {
      baseView: null,
      firmName: 'Firm',
      firmViews: [],
      personalViews: [],
      globalViews: [],
      isLoading: true,
      isSearching: false,
      lastQuery: ''
    }
  }

  public componentDidMount() {
    const { settings } = this.props
    const { baseViewId } = this.props
    if (!baseViewId) {
      return
    }
    const firmId = settings.getFirm().uniqueId
    const firmName = settings.getFirm().business.legalName
    this.setState({ firmName })

    this.firmViewsDataSet = this.createEntityDataSource(baseViewId, firmId, 'firm').setOnChange(() =>
      this.setState({ firmViews: this.firmViewsDataSet.entities })
    )
    this.personalViewsDataSet = this.createEntityDataSource(baseViewId, firmId, 'personal', true).setOnChange(() =>
      this.setState({ personalViews: this.personalViewsDataSet.entities })
    )
    this.vectorViewsDataSet = this.createEntityDataSource(
      baseViewId,
      vectorFirmId,
      'vector'
    ).setOnChange(() => this.setState({ globalViews: this.vectorViewsDataSet.entities }))

    Promise.all([
      apis.getStore().findRecord(baseViewId),
      this.firmViewsDataSet.find(),
      this.vectorViewsDataSet.find(),
      this.personalViewsDataSet.find(),
    ]).then(([ baseView ]) => {
      this.setState({ baseView, isLoading: false })
    })
  }

  public componentWillUnmount() {
    if (this.firmViewsDataSet) {
      this.firmViewsDataSet.dispose()
    }
    if (this.personalViewsDataSet) {
      this.personalViewsDataSet.dispose()
    }
    if (this.vectorViewsDataSet) {
      this.vectorViewsDataSet.dispose()
    }
  }

  public render() {
    const { baseViewId, viewContext } = this.props
    if (!baseViewId) {
      return this.renderTitle(false)
    }
    const tetherOptions = {
      attachment: 'top left',
      offset: { top: 0, left: 0 },
      targetAttachment: 'bottom left',
    }
    return (
      <TetherTarget
        // closeOnPortalClick={true}
        isEnabled={true}
        tethered={this.renderViewDropdown()}
        tetherOptions={tetherOptions}
      >
        {viewContext?.isWithinPortal ? this.renderCustomizeView() : this.renderTitle(true)}
      </TetherTarget>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Render functions
  //////////////////////////////////////////////////////////////////////////////

  private renderCustomizeView() {
    const { view } = this.props
    const title = view.displayName

    return (
      <div className="flex flex-row items-center">
        <h5
          className={classNames('u-ellipsis c-cardHeader-superTitle', {
            pointer: true,
          })}
          data-debug-id={`viewHeaderTitle:Customize View`}
        >
          Customize View
        </h5>
        <Icon
          className={classNames('ml1')}
          size={14}
          icon={IconNames.CHEVRON_DOWN}
        />
      </div>
    )
  }

  private renderTitle(isEnabled) {
    const { view } = this.props
    const title = view.displayName

    return (
      <div className="flex flex-row items-center">
        <h2
          className={classNames('u-ellipsis c-cardHeader-superTitle', {
            pointer: isEnabled,
          })}
          data-debug-id={`viewHeaderTitle:${title}`}
        >
          {title}
        </h2>
        <Icon
          className={classNames('ml1', { 'u-hide': !isEnabled })}
          icon={IconNames.CHEVRON_DOWN}
        />
      </div>
    )
  }

  private isActiveView(viewId) {
    const path = window.location.pathname

    if (path.indexOf('custom') >= 0) {
      return path.indexOf(`custom/${viewId}`) >= 0
    }

    return path.indexOf(viewId) >= 0
  }

  private renderViewLink(view, index, hasEditPermissions, isDefaultView=false) {
    const { settings, viewContext, baseViewId } = this.props
    const handleEdit = () => this.handleOpenViewConfigurationModal(view)
    const isEditEnabled =
      view.view.state.isEditEnabled === undefined || view.view.state.isEditEnabled

    const viewPath = makeViewLink(viewContext?.basePath, baseViewId, view.uniqueId, isDefaultView)

    return (
          <li
            className={classNames('c-dropdownList-item u-flex items-center', {
              'c-dropdownList-item--sm': settings.isAdmin || hasEditPermissions,
              'is-active': this.isActiveView(view.uniqueId)
            })}
            key={index}
          >
            <NavLink
              className="u-flexGrow c-viewChooser-name"
              key={view.uniqueId}
              to={viewPath}
            >
              {view.displayName}
            </NavLink>
            <Button
              className={classNames('u-bumperLeft', Classes.MINIMAL)}
              isDisabled={!settings.isAdmin && (!hasEditPermissions || !isEditEnabled)}
              onClick={handleEdit}
              size="sm"
            >
              Edit
            </Button>
          </li>

    )
  }

  private renderViewsSection(name, views, isEditable, isDefaultView=false) {
    if (_.isEmpty(views)) {
      return null
    }
    return (
      <Section className="c-section--collapse" subtitle={name} headerClassName="mh3">
        <ul className="c-dropdownList c-viewChooser-list">
          {_.map(views, (view, index) => this.renderViewLink(view, index, isEditable, isDefaultView))}
        </ul>
      </Section>
    )
  }

  private handleQuery = _.debounce((query) => {
    this.setState({ lastQuery: query })
    this.firmViewsDataSet.collection.query.setQuery(query)
    this.personalViewsDataSet.collection.query.setQuery(query)
    this.vectorViewsDataSet.collection.query.setQuery(query)
    this.setState({ isSearching: true })
    Promise.all([
      this.firmViewsDataSet.find(),
      this.personalViewsDataSet.find(),
      this.vectorViewsDataSet.find(),
    ]).then(() => {
      this.setState({ isSearching: false })
    })
  }, DEBOUNCE_TIMEOUT)

  private renderSearchInput() {
    const { isSearching } = this.state
    return (
      <div className='c-multiSelectFilter-search'>
        <FormTable>
          <InputField
            autoFocus={true}
            isLoading={isSearching}
            onChange={this.handleQuery}
            placeholder='Search'
          />
        </FormTable>
      </div>
    )
  }

  private renderViewDropdown() {
    const { settings } = this.props
    const { firmName, firmViews, isLoading, personalViews } = this.state
    if (isLoading) {
      return (
        <Popover className="collapse">
          <div className="c-multiSelectFilter-loading">
            <li className="c-dropdownList-item" onClick={this.handleSaveAsNew}>
              <LoadingSpinner size="xs" />
            </li>
          </div>
        </Popover>
      )
    }

    const defaultViews = this.getDefaultViews()
    return (
      <Popover className="collapse">
        {this.renderSearchInput()}
        {this.renderViewsSection('Personal Views', personalViews, true)}
        {/* Need to fix this, so that in TMS we dont see default views */}
        {settings.isBrokerTMSApplication()
          ? this.renderViewsSection('Default Views', defaultViews, false, true)
          : null}
        {this.renderViewsSection(`${firmName} Views`, firmViews, canEditFirmView(settings))}
        <li className="c-dropdownList-item" onClick={this.handleSaveAsNew}>
          Save As...
        </li>
      </Popover>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Private functions
  //////////////////////////////////////////////////////////////////////////////

  private getDefaultViews() {
    const { baseView, globalViews } = this.state
    return _.isEmpty(globalViews) ? [baseView] : globalViews
  }

  private createEntityDataSource(baseViewId, firmId, viewType, isPersonal = false) {
    const { settings } = this.props
    const filters = [
      {
        path: 'view.extends',
        type: 'containsEdge',
        value: {
          entityId: baseViewId,
        },
      },
      {
        path: 'owner.firm',
        type: 'matchEdge',
        value: {
          entityId: firmId,
        },
      },
      {
        path: 'view.scope',
        type: 'match',
        values: [
          'personal'
        ],
        isNegation: !isPersonal
      }
    ]
    if (isPersonal) {
      filters.push({
        path: 'owner.user',
        type: 'matchEdge',
        value: {
          entityId: settings.getUser().uniqueId
        }
      })
    }

    const orders = [
      {
        path: 'view.name',
        type: 'ascending',
      },
    ]
    // https://app.asana.com/0/504073247464833/708505441353687

    const debug = { context: `viewChooser--${viewType}` }
    return new EntityDataSource({
      entityType: '/1.0/entities/metadata/view.json',
      filters,
      metadata: {
        size: 250,
      },
      orders,
      debug,
    })
  }

  private handleSaveAsNew = () => {
    const { baseViewId, settings, view, viewContext } = this.props
    const { globalViews } = this.state
    const newView: any = view.clone()
    const uniqueId = view.get('uniqueId')

    // NOTE: when cloning the base view, we need to extends from it
    if (uniqueId === baseViewId) {
      newView.view.extends = [{ entityId: baseViewId }]
      delete newView.view.uiSchema
    }
    // NOTE: when cloning a vector view, we want to extend its uiSchema
    if (_.find(globalViews, { uniqueId })) {
      newView.view.extends.push({ entityId: uniqueId })
      delete newView.view.uiSchema
    }
    // NOTE: even if the parent view was locked down, allow the new view to be edited
    newView.set('view.state.isEditEnabled', true)
    newView.set('owner.user', {
      entityId: settings.getUser().user.entity.uniqueId,
      displayName: settings.getUser().user.entity.displayName,
    })
    newView.set('owner.firm', {
      entityId: settings.getFirm().uniqueId,
      displayName: settings.getFirm().business.legalName,
    })

    const defaultView = _.first(this.getDefaultViews())
    ViewConfigurationModal.open({
      defaultView,
      isDeletable: false,
      view: newView,
      canCreateFirmView: canEditFirmView(settings),
      basePath: viewContext?.basePath,
    })
  }

  private refreshViews = () => {
    // If we refresh from ES immediately, the server won't have fully processed the update to the API / DB
    _.delay(() => this.handleQuery(this.state.lastQuery), 1000)
  }

  private handleOpenViewConfigurationModal = (view) => {
    const { settings, viewContext } = this.props
    const defaultView = _.first(this.getDefaultViews())

    ViewConfigurationModal.open({
      defaultView,
      isDeletable: true,
      view,
      canCreateFirmView: canEditFirmView(settings),
      onClose: this.refreshViews,
      basePath: viewContext?.basePath,
    })
  }
}
