import React from "react"

import { IBaseProps } from "browser/components/atomic-elements/atoms/base-props"
import { TableActionBar } from 'browser/components/atomic-elements/organisms/table-action-bar/table-action-bar'
import _ from "lodash"
import { DefaultTableActionButtons } from "browser/components/atomic-elements/organisms/table-action-bar/default-table-action-buttons"

interface IDefaultTableActionBarProps extends IBaseProps {
  entityPath: string
  selection: any[]
  actions?: any
  onRefresh?: () => void
}

export class DefaultTableActionBar extends React.Component<IDefaultTableActionBarProps> {
  public render() {
    const { selection, actions } = this.props

    if (selection?.length <= 1 || actions?.length === 0) {
      return null
    }

    return (
      <TableActionBar
        actionButtons={this.renderActionButtons()}
        selection={selection}
      />
    )
  }

  public renderActionButtons() {
    const { props } = this
    return <DefaultTableActionButtons {...props} />
  }
}
