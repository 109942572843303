/** 
 * @note when adding icons make sure to add them to:
 * - IconNames enum
 * - IconName type
 * - _custom-icon.scss
 */
export const enum IconNames {
  GREEN_CHECK = 'green-check',
  X_BOX = 'x-box'
}

export type IconName =  IconNames.GREEN_CHECK | IconNames.X_BOX
