import { browserHistory } from 'browser/history'
import _ from 'lodash'
import React from 'react'
import Isvg from 'react-inlinesvg'
import { Route, Switch, withRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { EntityIndex as EntityPage } from 'browser/app/pages/app/entity'
import { ViewsIndex as ViewPage } from 'browser/app/pages/app/views'
import { AppBody } from 'browser/components/atomic-elements/atoms/app-body'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { MasterDetail } from 'browser/components/atomic-elements/atoms/master-detail/master-detail'
import { EntityPageContainer } from 'browser/components/atomic-elements/organisms/entity/entity-page-container'
import { IRenditionBillingViewHeaderOptions } from 'browser/components/atomic-elements/organisms/view-header/rendition-billing-view-header/'
import { Entity } from 'shared-libs/models/entity'
import { ISalesOrdersViewHeaderOptions } from '../view-header/sales-order-view-header'
import { ViewContext } from 'browser/contexts/view-context'

const SentryRoute = Sentry.withSentryRouting(Route);

const LoadDocsClippy = require('images/app/loaddocs-clippy.svg')

/**
 * @uiComponent
 */
interface IEntityMasterDetailLayoutProps extends IBaseProps {
  addEntityButtonText?: string
  addEntitySchema?: string
  addEntityUiSchema?: string
  addEntityDefaultValue?: any
  addImportSchema: string
  availableFilters: any
  blacklistedFilters: any
  filterProps: any
  baseViewId?: string
  dataSet: any
  detailPanel: React.ReactElement<any>
  detailPanelDefaultSize?: number
  entitySchema?: string | Entity
  hidePageSummary?: boolean
  location: any
  mapView: React.ReactElement<any>
  match: any
  onCloseDetailPanel: () => void
  onFiltersChange: (filters: any) => void
  onViewModeChange: (viewMode: string) => void
  onReviewAndPublishPressed?: any
  onReviewAndReImagingQueuedPressed?: any
  onReviewAndReImagingIndexedPressed?: any
  renditionBillingViewHeaderOptions?: IRenditionBillingViewHeaderOptions
  salesOrdersViewHeaderOptions?: ISalesOrdersViewHeaderOptions
  selection: any[]
  showAddEntityButton: boolean
  showExportButton: boolean
  showImportButton: boolean
  showRenditionBillingButton: boolean
  showRenditionBillingStatusSelector: boolean
  showFilters: boolean
  showImagingButtons: boolean
  showViewModeToggle: boolean
  showPrintoutButton?: boolean
  tableView: React.ReactElement<any>
  view: any
  viewMode: 'list' | 'listDetail'
  actions?: any
}

interface IEntityMasterDetailLayoutState {
  entityPageIndex: any
  showMap: boolean
}

@withRouter
export class EntityMasterDetailLayout extends React.PureComponent<
  IEntityMasterDetailLayoutProps,
  IEntityMasterDetailLayoutState
> {
  public static get defaultProps(): Partial<IEntityMasterDetailLayoutProps> {
    return {
      selection: [],
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      entityPageIndex: this.getEntityPageIndex(this.props),
      showMap: false,
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { entityPageIndex } = this.state
    const hasPageIndex = !_.isNil(entityPageIndex)
    this.setState({
      entityPageIndex: this.getEntityPageIndex(nextProps),
    })
  }

  public render() {
    const {
      addEntityButtonText,
      addEntitySchema,
      addEntityUiSchema,
      addEntityDefaultValue,
      addImportSchema,
      availableFilters,
      blacklistedFilters,
      filterProps,
      baseViewId,
      dataSet,
      detailPanelDefaultSize,
      entitySchema,
      hidePageSummary,
      mapView,
      match,
      onFiltersChange,
      onReviewAndPublishPressed,
      onReviewAndReImagingQueuedPressed,
      onReviewAndReImagingIndexedPressed,
      onViewModeChange,
      selection,
      renditionBillingViewHeaderOptions,
      salesOrdersViewHeaderOptions,
      showAddEntityButton,
      showImportButton,
      showFilters,
      showExportButton,
      showImagingButtons,
      showRenditionBillingButton,
      showRenditionBillingStatusSelector,
      showViewModeToggle,
      showPrintoutButton,
      view,
      viewMode,
    } = this.props
    // TODO(peter/louis): Not ready for this yet
    // const showDetailPanel = !match.isExact || viewMode === 'listDetail'
    const showDetailPanel = !match.isExact
    const isFullScreen = viewMode === 'list'
    const showHeader = !(isFullScreen && showDetailPanel)

    return (
      <AppNavigatorContext.Consumer>
        {({ settings }) => (
          <AppBody className="vertical">
            <EntityPageContainer
              addEntityButtonText={addEntityButtonText}
              addEntitySchema={addEntitySchema}
              addEntityUiSchema={addEntityUiSchema}
              addEntityDefaultValue={addEntityDefaultValue}
              addImportSchema={addImportSchema}
              availableFilters={availableFilters}
              blacklistedFilters={blacklistedFilters}
              filterProps={filterProps}
              baseViewId={baseViewId}
              dataSet={dataSet}
              entitySchema={entitySchema}
              handleToggleMap={this.handleToggleMap}
              hidePageSummary={hidePageSummary}
              mapView={mapView}
              match={match}
              onFiltersChange={onFiltersChange}
              onReviewAndPublishPressed={onReviewAndPublishPressed}
              onReviewAndReImagingQueuedPressed={onReviewAndReImagingQueuedPressed}
              onReviewAndReImagingIndexedPressed={onReviewAndReImagingIndexedPressed}
              onViewModeChange={onViewModeChange}
              selection={selection}
              settings={settings}
              renditionBillingViewOptions={renditionBillingViewHeaderOptions}
              salesOrdersViewOptions={salesOrdersViewHeaderOptions}
              showAddEntityButton={showAddEntityButton}
              showFilters={showFilters}
              showExportButton={showExportButton}
              showImagingButtons={showImagingButtons}
              showImportButton={showImportButton}
              showRenditionBillingButton={showRenditionBillingButton}
              showRenditionBillingStatusSelector={showRenditionBillingStatusSelector}
              showViewModeToggle={showViewModeToggle}
              showPrintoutButton={showPrintoutButton}
              showHeader={showHeader}
              totalItems={dataSet.count}
              view={view}
              viewMode={viewMode}
            >
              <MasterDetail
                isStackLayout={isFullScreen}
                showDetailPanel={showDetailPanel}
                detailPanelDefaultSize={detailPanelDefaultSize}
                masterPanel={this.renderMasterPanel()}
                detailPanel={this.renderDetailPanel()}
              />
            </EntityPageContainer>
          </AppBody>
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  private renderMasterPanel() {
    const { mapView, tableView } = this.props
    const { showMap } = this.state
    return (
      <div className="grid-block vertical">
        {showMap ? mapView : undefined}
        {tableView}
      </div>
    )
  }

  private renderDetailPanel() {
    if (this.props.showImagingButtons) {
      return this.renderImagingDetailView()
    }
    return this.renderDetailRoutes()
  }

  private renderDetailRoutes() {
    const { match, selection, viewMode } = this.props

    // TODO(peter/louis): enabel when we have the first item selected
    // if (!(selection.length === 1) && viewMode === 'listDetail') {
    //   return this.renderEmptyListDetail()
    // }
    const route = match.url
    return (
      <Switch>
        <SentryRoute path={`${route}/entity/:entityId`} render={this.handleRenderEntityDetail} />
        <SentryRoute path={`${route}/view/:viewId/custom/:customViewId`} render={this.handleRenderViewDetail} />
        <SentryRoute path={`${route}/view/:viewId`} render={this.handleRenderViewDetail} />
      </Switch>
    )
  }

  private renderImagingDetailView() {
    const { match, selection } = this.props
    const route = match.url
    if (!selection || _.isEmpty(selection)) {
      return null
    }
    return (
      <div className="grid-block">
        <Switch>
          <SentryRoute path={`${route}/entity/:entityId`} render={this.handleRenderEntityDetail} />
        </Switch>
      </div>
    )
  }

  private renderEmptyListDetail() {
    return (
      <div
        className="flex flex-column items-center justify-center bl b--light-gray w-100"
        style={{ minWidth: 540 }}
      >
        <div style={{ height: 100, width: 70 }}>
          <Isvg src={LoadDocsClippy} />
        </div>
        <div className="pa3">Please select a row in the table or switch to list mode above.</div>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // Handlers
  //////////////////////////////////////////////////////////////////////////////

  private handleToggleMap = () => {
    const { showMap } = this.state
    this.setState({ showMap: !showMap })
  }

  private handleRenderEntityDetail = (props) => {
    const { history, location, match, staticContext } = props
    const { dataSet, onCloseDetailPanel, selection, viewMode, actions } = this.props
    // There is a timing issue. We don't want to render detail panel is showDetailPanel
    // is false. If we remove this there is an artifact when closing detail panel
    const showDetailPanel = selection.length === 1
    if (!showDetailPanel) {
      return null
    }
    const firstSelection = _.first(selection)
    const entity = firstSelection instanceof Entity ? firstSelection : null
    const isFullScreen = viewMode === 'list'
    return (
      <EntityPage
        isFullScreen={isFullScreen}
        entity={entity}
        history={history}
        location={location}
        match={match}
        navigateToPrevPage={this.handleNavigateToPrevPage}
        navigateToNextPage={this.handleNavigateToNextPage}
        navigateToPage={this.handleNavigateToPage}
        onClose={onCloseDetailPanel}
        rendererState={{ dataSet, actions }}
      />
    )
  }

  private handleRenderViewDetail = (props) => {
    const { history, location, match } = props
    const { dataSet, onCloseDetailPanel } = this.props
    const viewProps = {
      filters: dataSet.filters,
      onClose: onCloseDetailPanel,
    }

    return (
      <ViewContext.Provider value={{basePath: this.props.match.url}}>
        <ViewPage
          className="grid-block"
          history={history}
          location={location}
          match={match}
          pageProps={viewProps}
        />
      </ViewContext.Provider>
    )
  }

  private getEntityPageIndex(props) {
    const { selection, dataSet } = props
    const firstSelection: any = _.first(selection)
    if (dataSet && !dataSet.isLoading && firstSelection) {
      return _.findIndex(dataSet.entities, { uniqueId: firstSelection.data.uniqueId })
    }
    return undefined
  }

  private handleNavigateToNextPage = () => {
    const dataSet: any = this.props.dataSet
    const { onCloseDetailPanel } = this.props
    const { entityPageIndex } = this.state

    if (entityPageIndex === dataSet.content.length - 1) {
      dataSet.handleLoadNextPage().then(() => {
        if (dataSet.entities.length - 1 === entityPageIndex) {
          onCloseDetailPanel()
          return
        }
        const entity = dataSet.entities[entityPageIndex + 1]
        this.navigateToPage(entity)
      })
    } else {
      const entity = dataSet.entities[entityPageIndex + 1]
      this.navigateToPage(entity)
      dataSet.find()
    }
  }

  private handleNavigateToPrevPage = () => {
    const dataSet: any = this.props.dataSet
    const { entityPageIndex } = this.state
    if (entityPageIndex === 0) {
      return
    }
    const entity = dataSet.entities[entityPageIndex - 1]
    this.navigateToPage(entity)
  }

  private handleNavigateToPage = (entity, state) => {
    this.navigateToPage(entity, state)
  }

  private navigateToPage(entity, state?) {
    const { viewMode } = this.props
    const { match } = this.props
    const isFullScreen: boolean = viewMode === 'list'
    const pathName = match.path.replace(':entityId', entity.uniqueId)
    const search = isFullScreen ? 'isFullScreen=1' : undefined
    browserHistory.push({
      pathname: match.url + '/entity/' + entity.uniqueId,
      search,
      state,
    })
  }
}
