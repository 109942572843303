import md5 from 'md5'
import _join from 'lodash/join'
import _compact from 'lodash/compact'
import { JsonValue, PromiseOr } from '../helpers/types'

export type OperationMode = 'sync' | 'async'
export type IsAsync<Type extends OperationMode> = Type extends 'async' ? true : false

export type SerializableKey = string | number | boolean
export type SerializableValue = JsonValue

export const DEFAULT_CACHE_SIZE = 10
const HASH_THRESHOLD = 256

export interface CacheItem<V> {
  value: V
  expiry?: number
}

/**
 * Base class for an LRU Cache w/ optional TTL.
 *
 * Uses a Map for its built-in LRU functionality. Supports TTL by wrapping
 * values as an object that may include an expiry. Supports sync or async
 * operation modes.
 */
export class LRUCacheWithTTL<K = any, V = any, Mode extends OperationMode = OperationMode> {
  protected readonly lruCache: Map<string, CacheItem<V>>

  /**
   * @param mode Runtime value to help decide whether to operate asynchronously.
   */
  protected constructor(
    protected readonly mode: OperationMode,
    private readonly capacity: number = DEFAULT_CACHE_SIZE,
    protected readonly tag?: string
  ) {
    this.lruCache = new Map()
  }

  public keys(): PromiseOr<string[], IsAsync<Mode>> {
    return this.resolve(Array.from(this.lruCache.keys()))
  }

  public size(): PromiseOr<number, IsAsync<Mode>> {
    return this.resolve(this.lruCache.size)
  }

  public has(k: K): PromiseOr<boolean, IsAsync<Mode>> {
    const key = this.normalizeKey(k)
    return this.resolve(this.lruCache.has(key))
  }

  public get(k: K): PromiseOr<V | undefined, IsAsync<Mode>> {
    const key = this.normalizeKey(k)
    const item = this.lruCache.get(key)
    if (!item) {
      return this.resolve()
    }

    // if the item has expired, delete it
    if (item.expiry != null && item.expiry < Date.now()) {
      return this.deleteHelper(key) as PromiseOr<undefined, IsAsync<Mode>>
    }

    // refresh last used
    this.lruCache.delete(key)
    this.lruCache.set(key, item)
    return this.resolve(item.value)
  }

  public set(k: K, value: V, ttlMilliseconds?: number): PromiseOr<void, IsAsync<Mode>> {
    const key = this.normalizeKey(k)
    const expiry = ttlMilliseconds != null ? Date.now() + ttlMilliseconds : undefined

    const item: CacheItem<V> = {
      value,
    }

    if (expiry != null) {
      item.expiry = expiry
    }

    const needsEviction = this.prepareSet(key, item)

    if (this.mode === 'async') {
      return this.resolve(
        (async () => {
          if (needsEviction) {
            await this.evict()
          }
          await this.onSet(key, item)
          this.lruCache.set(key, item)
        })()
      )
    } else {
      if (needsEviction) {
        void this.evict()
      }
      void this.onSet(key, item)
      this.lruCache.set(key, item)
    }
  }

  /** Determines whether eviction is necessary */
  private prepareSet(key: string, item: CacheItem<V>): boolean {
    if (this.lruCache.has(key)) {
      item.expiry = this.lruCache.get(key)?.expiry ?? item.expiry
      this.lruCache.delete(key)
      return false
    }
    return this.lruCache.size >= this.capacity
  }

  public delete(k: K): PromiseOr<undefined, IsAsync<Mode>> {
    const key = this.normalizeKey(k)
    return this.deleteHelper(key) as PromiseOr<undefined, IsAsync<Mode>>
  }

  public clear(): PromiseOr<void, IsAsync<Mode>> {
    this.lruCache.clear()
    return this.onClear()
  }

  private normalizeKey(key: K) {
    const keyStr = String(key)
    const normalizedKey = keyStr.length < HASH_THRESHOLD ? key : md5(keyStr)
    return _join(_compact([this.tag, normalizedKey]), ':')
  }

  protected belongsToCache(key: string) {
    return !this.tag || key.startsWith(this.tag)
  }

  private evict(): PromiseOr<void, IsAsync<Mode>> {
    const key = this.lruCache.keys().next().value
    if (key) {
      return this.resolve(this.deleteHelper(key))
    }
  }

  private deleteHelper(key: string): PromiseOr<void, IsAsync<Mode>> {
    if (this.mode === 'async') {
      return this.resolve(
        (async () => {
          await this.onDelete(key)
          this.lruCache.delete(key)
        })()
      )
    } else {
      void this.onDelete(key)
      this.lruCache.delete(key)
    }
  }

  /** Helps resolve an async or sync value as a {@link PromiseOr} */
  protected resolve<T>(value: Promise<T> | T = undefined): PromiseOr<T, IsAsync<Mode>> {
    const result = this.mode === 'async' ? Promise.resolve(value) : value
    return result as PromiseOr<T, IsAsync<Mode>>
  }

  /* no-op; subclass may override */
  protected onDelete(key: string): PromiseOr<void, IsAsync<Mode>> {
    return
  }

  /* no-op; subclass may override */
  protected onSet(key: string, item: CacheItem<V>): PromiseOr<void, IsAsync<Mode>> {
    return
  }

  /* no-op; subclass may override */
  protected onClear(): PromiseOr<void, IsAsync<Mode>> {
    return
  }
}

/** Represents a cache entry read from storage. */
export type StoredEntry = [string, string | null]

/** Extends the base cache with a way to initialize from some external source. */
export abstract class PersistentLRUCacheWithTTL<
  K extends SerializableKey = SerializableKey,
  V extends SerializableValue = SerializableValue,
  Mode extends OperationMode = OperationMode
> extends LRUCacheWithTTL<K, V, Mode> {
  constructor(operationType: Mode, capacity = DEFAULT_CACHE_SIZE, tag: string) {
    if (!tag) {
      throw new Error('A tag is required in persistent shared caches')
    }
    super(operationType, capacity, tag)
  }

  /** Loads relevant entries from the backing storage */
  protected abstract loadEntriesFromStorage(): PromiseOr<Array<StoredEntry>, IsAsync<Mode>>

  /** Initializes the cache from its backing storage */
  public initialize(): PromiseOr<void, IsAsync<Mode>> {
    const entriesResult = this.loadEntriesFromStorage()

    if (this.mode === 'async') {
      return (async () => {
        const entries = (await entriesResult) as Array<StoredEntry>
        this.populateCache(entries)
      })() as PromiseOr<void, IsAsync<Mode>>
    } else {
      const entries = entriesResult as Array<StoredEntry>
      this.populateCache(entries)
    }
  }

  /** Populate the cache with entries from its backing storage */
  private populateCache(entries: Array<StoredEntry>): void {
    this.lruCache.clear()

    for (const [key, entry] of entries) {
      const item = JSON.parse(entry)
      this.lruCache.set(key, item)
    }
  }

  protected belongsToCache(key: string) {
    return key.startsWith(this.tag)
  }
}

export class LRUCache<
  K = any,
  V = any,
  Mode extends OperationMode = OperationMode
> extends LRUCacheWithTTL<K, V, Mode> {
  /* hides the TTL param from signature */
  public set(key: K, value: V): PromiseOr<void, IsAsync<Mode>> {
    return super.set(key, value)
  }
}

abstract class PersistentLRUCache<
  K extends SerializableKey = SerializableKey,
  V extends SerializableValue = SerializableValue,
  Mode extends OperationMode = OperationMode
> extends PersistentLRUCacheWithTTL<K, V, Mode> {
  /* hides the TTL param from signature */
  public set(key: K, value: V): PromiseOr<void, IsAsync<Mode>> {
    return super.set(key, value)
  }
}

/* convenience classes */

export class SyncLRUCache<K = any, V = any> extends LRUCache<K, V, 'sync'> {
  constructor(capacity: number, tag?: string) {
    super('sync', capacity, tag)
  }
}

export class AsyncLRUCache<K = any, V = any> extends LRUCache<K, V, 'async'> {
  constructor(capacity: number, tag?: string) {
    super('async', capacity, tag)
  }
}

export class SyncLRUCacheWithTTL<K = any, V = any> extends LRUCacheWithTTL<K, V, 'sync'> {
  constructor(capacity: number = DEFAULT_CACHE_SIZE, tag?: string) {
    super('sync', capacity, tag)
  }
}

export class AsyncLRUCacheWithTTL<K = any, V = any> extends LRUCacheWithTTL<K, V, 'async'> {
  constructor(capacity: number = DEFAULT_CACHE_SIZE, tag?: string) {
    super('async', capacity, tag)
  }
}

export abstract class SyncPersistentLRUCache<
  K extends SerializableKey = SerializableKey,
  V extends SerializableValue = SerializableValue
> extends PersistentLRUCache<K, V, 'sync'> {
  constructor(capacity: number = DEFAULT_CACHE_SIZE, tag?: string) {
    super('sync', capacity, tag)
  }
}

export abstract class AsyncPersistentLRUCache<
  K extends SerializableKey = SerializableKey,
  V extends SerializableValue = SerializableValue
> extends PersistentLRUCache<K, V, 'async'> {
  constructor(capacity: number = DEFAULT_CACHE_SIZE, tag?: string) {
    super('async', capacity, tag)
  }
}

export abstract class SyncPersistentLRUCacheWithTTL<
  K extends SerializableKey = SerializableKey,
  V extends SerializableValue = SerializableValue
> extends PersistentLRUCacheWithTTL<K, V, 'sync'> {
  constructor(capacity: number = DEFAULT_CACHE_SIZE, tag?: string) {
    super('sync', capacity, tag)
  }
}

export abstract class AsyncPersistentLRUCacheWithTTL<
  K extends SerializableKey = SerializableKey,
  V extends SerializableValue = SerializableValue
> extends PersistentLRUCacheWithTTL<K, V, 'async'> {
  constructor(capacity: number = DEFAULT_CACHE_SIZE, tag?: string) {
    super('async', capacity, tag)
  }
}
