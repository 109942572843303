import _ from 'lodash'
import { evaluateExpression } from './evaluation'
import { ApplicationContext } from './formulas/applicationContext'
import * as Formulas from './formulas/modules'
import { FormulaComponentInfo } from '../components/entity/formula-component-registry'

type FormulaContext = Record<string, Function>
type FormulaLocalContext = Record<string, Function>

export interface IFormulaLocalContext {
  applicationContext?: ApplicationContext
  componentInfo?: FormulaComponentInfo
}

const defaultApplicationContext: ApplicationContext = {
  getDeeplinkHost: () => { throw 'getDeeplinkHost not configured' },
  getLocale: () => { return undefined },
  getPlatform: () => { return undefined },
  getTruetimeOffset: () => { return 0 },
  getWindowDimensions: () => { return {} },
  getStore: () => { return undefined },
  sendIntent: () => { return Promise.resolve() },
  sendBroadcast: () => { return Promise.resolve() },
}

class FormulaFunctionRegistry {
  private applicationContext: ApplicationContext
  private formulaContext: FormulaContext
  private formulaLocalContext: FormulaLocalContext

  constructor() {
    this.applicationContext = defaultApplicationContext
    this.formulaContext = {}
    this.formulaLocalContext = {}
  }

  public registerApplicationContext(context: Partial<ApplicationContext>): void {
    _.assign(this.applicationContext, context)
  }

  public getFormulaContext(): Record<string, Function> {
    return this.formulaContext
  }

  public getApplicationContext(): Partial<ApplicationContext> {
    return this.applicationContext
  }

  public registerFunction(name: string, customFunction: Function, bindLocalContext = false): void {
    this.formulaContext[name] = customFunction.bind(this.applicationContext)
    /**
     * for custom function like MEMOIZE that required access local context such as
     * current component info, frames, etc ...
     */
    if (bindLocalContext) {
      this.formulaLocalContext[name] = customFunction
    }
  }

  public bindToLocalContext(key: string)  {
    return this.formulaLocalContext[key]
  }

  public registerFunctionFromString(name: string, code: string): void {
    const customFunction = evaluateExpression({ _ }, code)
    this.registerFunction(name, customFunction)
  }

}

export const FormulaRegistry = new FormulaFunctionRegistry()
export const CustomFormulas = FormulaRegistry.getFormulaContext()

/* array */
FormulaRegistry.registerFunction('CHUNK', Formulas.chunk)
FormulaRegistry.registerFunction('COMPACT', Formulas.compact)
FormulaRegistry.registerFunction('CONCAT', Formulas.concat)
FormulaRegistry.registerFunction('DIFFERENCE', Formulas.difference)
FormulaRegistry.registerFunction('DROP', Formulas.drop)
FormulaRegistry.registerFunction('FILL', Formulas.fill)
FormulaRegistry.registerFunction('FLATTEN', Formulas.flatten)
FormulaRegistry.registerFunction('FLATTEN_DEEP', Formulas.flattenDeep)
FormulaRegistry.registerFunction('HEAD', Formulas.head)
FormulaRegistry.registerFunction('INITIAL', Formulas.initial)
FormulaRegistry.registerFunction('INTERSECTION', Formulas.intersection)
FormulaRegistry.registerFunction('JOIN', Formulas.join)
FormulaRegistry.registerFunction('LAST', Formulas.last)
FormulaRegistry.registerFunction('NTH', Formulas.nth)
FormulaRegistry.registerFunction('INDEX', Formulas.nth)
FormulaRegistry.registerFunction('REVERSE', Formulas.reverse)
FormulaRegistry.registerFunction('SLICE', Formulas.slice)
FormulaRegistry.registerFunction('TAIL', Formulas.tail)
FormulaRegistry.registerFunction('TAKE', Formulas.take)
FormulaRegistry.registerFunction('TAKE_RIGHT', Formulas.takeRight)
FormulaRegistry.registerFunction('UNION', Formulas.union)
FormulaRegistry.registerFunction('UNIQ', Formulas.uniq)
FormulaRegistry.registerFunction('UNZIP', Formulas.unzip)
FormulaRegistry.registerFunction('WITHOUT', Formulas.without)
FormulaRegistry.registerFunction('XOR', Formulas.xor)
FormulaRegistry.registerFunction('ZIP', Formulas.zip)
FormulaRegistry.registerFunction('JOINSTR', Formulas.joinStr) // legacy

/* checksum */
FormulaRegistry.registerFunction('CHECKDIGIT', Formulas.checkDigit)

/* collections */
FormulaRegistry.registerFunction('COUNT_BY', Formulas.countBy)
FormulaRegistry.registerFunction('EVERY', Formulas.every)
FormulaRegistry.registerFunction('FILTER', Formulas.filter)
FormulaRegistry.registerFunction('FIND', Formulas.find)
FormulaRegistry.registerFunction('FIND_LAST', Formulas.findLast)
FormulaRegistry.registerFunction('FLATMAP', Formulas.flatMap)
FormulaRegistry.registerFunction('GROUP_BY', Formulas.groupBy)
FormulaRegistry.registerFunction('INCLUDES', Formulas.includes)
FormulaRegistry.registerFunction('KEY_BY', Formulas.keyBy)
FormulaRegistry.registerFunction('MAP', Formulas.map)
FormulaRegistry.registerFunction('ORDER_BY', Formulas.orderBy)
FormulaRegistry.registerFunction('PARTITION', Formulas.partition)
FormulaRegistry.registerFunction('REJECT', Formulas.reject)
FormulaRegistry.registerFunction('SAMPLE', Formulas.sample)
FormulaRegistry.registerFunction('SAMPLE_SIZE', Formulas.sampleSize)
FormulaRegistry.registerFunction('SHUFFLE', Formulas.shuffle)
FormulaRegistry.registerFunction('SIZE', Formulas.size)
FormulaRegistry.registerFunction('LEN', Formulas.size)
FormulaRegistry.registerFunction('SOME', Formulas.some)
FormulaRegistry.registerFunction('SORT_BY', Formulas.sortBy)

/* common */
FormulaRegistry.registerFunction('GET', Formulas.get)
FormulaRegistry.registerFunction('IS_EQUAL', Formulas.equal)
FormulaRegistry.registerFunction('DEFAULT', Formulas.defaultTo)
FormulaRegistry.registerFunction('IS_NUMBER', Formulas.isNumber)
FormulaRegistry.registerFunction('IS_STRING', Formulas.isString)
FormulaRegistry.registerFunction('IS_NIL', Formulas.isNil)
FormulaRegistry.registerFunction('IS_NULL', Formulas.isNull)
FormulaRegistry.registerFunction('IS_UNDEFINED', Formulas.isUndefined)
FormulaRegistry.registerFunction('IS_EMPTY', Formulas.isEmpty)
FormulaRegistry.registerFunction('IF', Formulas._if)
FormulaRegistry.registerFunction('STRINGIFY', Formulas.stringify)
FormulaRegistry.registerFunction('MATCHES_PROPERTY', Formulas.matchesProperty)
FormulaRegistry.registerFunction('VALUES', Formulas.values)
FormulaRegistry.registerFunction('KEYS', Formulas.keys)



/* entity */
FormulaRegistry.registerFunction('ENTITY_TYPE', Formulas.entityType)
FormulaRegistry.registerFunction('ENTITY_EDGE', Formulas.entityEdge)
FormulaRegistry.registerFunction('EDGE_FROM_ENTITY', Formulas.edgeFromEntity)
FormulaRegistry.registerFunction('DEFERRED_USER_PHONE', Formulas.deferredUserPhone)
FormulaRegistry.registerFunction('DEFERRED_USER_EMAIL', Formulas.deferredUserEmail)
FormulaRegistry.registerFunction('DEFERRED_USER_ID', Formulas.deferredUserId)
FormulaRegistry.registerFunction('DENORMALIZE_EDGE', Formulas.denormalizeEdge)

/* math */
FormulaRegistry.registerFunction('ABS', Formulas.abs)
FormulaRegistry.registerFunction('CEILING', Formulas.ceil)
FormulaRegistry.registerFunction('CEIL', Formulas.ceil)
FormulaRegistry.registerFunction('FLOOR', Formulas.floor)
FormulaRegistry.registerFunction('ROUND', Formulas.round)
FormulaRegistry.registerFunction('SUM', Formulas.sum)
FormulaRegistry.registerFunction('SUM_BY', Formulas.sumBy)
FormulaRegistry.registerFunction('MAX', Formulas.max)
FormulaRegistry.registerFunction('MIN', Formulas.min)
FormulaRegistry.registerFunction('LN', Formulas.ln)
FormulaRegistry.registerFunction('LOG', Formulas.log)
FormulaRegistry.registerFunction('EXP', Formulas.exp)
FormulaRegistry.registerFunction('POW', Formulas.pow)
FormulaRegistry.registerFunction('SIGN', Formulas.sign)
FormulaRegistry.registerFunction('IS_EVEN', Formulas.isEven)
FormulaRegistry.registerFunction('IS_ODD', Formulas.isOdd)
FormulaRegistry.registerFunction('GCD', Formulas.gcd)
FormulaRegistry.registerFunction('LCM', Formulas.lcm)
FormulaRegistry.registerFunction('CLAMP', Formulas.clamp)
FormulaRegistry.registerFunction('IN_RANGE', Formulas.inRange)
FormulaRegistry.registerFunction('RANDOM', Formulas.random)
FormulaRegistry.registerFunction('DIFFZERO', Formulas.diffZero) // legacy
FormulaRegistry.registerFunction('DISTANCE', Formulas.geoDistance)

/* stats */
FormulaRegistry.registerFunction('MEDIAN', Formulas.median)
FormulaRegistry.registerFunction('MEAN', Formulas.mean)
FormulaRegistry.registerFunction('STDDEV', Formulas.stddev)
FormulaRegistry.registerFunction('VARIANCE', Formulas.variance)

/* string */
FormulaRegistry.registerFunction('CAMELCASE', Formulas.camelCase)
FormulaRegistry.registerFunction('CAPITALIZE', Formulas.capitalize)
FormulaRegistry.registerFunction('LOWER', Formulas.lower)
FormulaRegistry.registerFunction('UPPER', Formulas.upper)
FormulaRegistry.registerFunction('PAD', Formulas.pad)
FormulaRegistry.registerFunction('PAD_LEFT', Formulas.padStart)
FormulaRegistry.registerFunction('PAD_RIGHT', Formulas.padEnd)
FormulaRegistry.registerFunction('SPLIT', Formulas.split)
FormulaRegistry.registerFunction('REPLACE', Formulas.replace)
FormulaRegistry.registerFunction('STARTS_WITH', Formulas.startsWith)
FormulaRegistry.registerFunction('ENDS_WITH', Formulas.endsWith)
FormulaRegistry.registerFunction('PARSE_INT', Formulas.parseInt)
FormulaRegistry.registerFunction('PARSE_FLOAT', Formulas._parseFloat)
FormulaRegistry.registerFunction('TRIM', Formulas.trim)
FormulaRegistry.registerFunction('TRIM_LEFT', Formulas.trimStart)
FormulaRegistry.registerFunction('TRIM_RIGHT', Formulas.trimEnd)
FormulaRegistry.registerFunction('TRUNCATE', Formulas.truncate)
FormulaRegistry.registerFunction('URI_ENCODE', Formulas.uriEncode)
FormulaRegistry.registerFunction('URI_ENCODE_COMPONENT', Formulas.uriEncodeComponent)
FormulaRegistry.registerFunction('URI_DECODE', Formulas.uriDecode)
FormulaRegistry.registerFunction('URI_DECODE_COMPONENT', Formulas.uriDecodeComponent)
FormulaRegistry.registerFunction('SNAKE_CASE', Formulas.snakeCase)


/* time */
FormulaRegistry.registerFunction('UTC', Formulas.utc)
FormulaRegistry.registerFunction('ADD_DATE', Formulas.addDate)
FormulaRegistry.registerFunction('SUBTRACT_DATE', Formulas.substractDate)
FormulaRegistry.registerFunction('NOW', Formulas.now)
FormulaRegistry.registerFunction('TODAY', Formulas.today)
FormulaRegistry.registerFunction('START_OF', Formulas.startOf)
FormulaRegistry.registerFunction('END_OF', Formulas.endOf)
FormulaRegistry.registerFunction('IS_BEFORE', Formulas.isBefore)
FormulaRegistry.registerFunction('IS_AFTER', Formulas.isAfter)
FormulaRegistry.registerFunction('IS_BETWEEN', Formulas.isBetween)
FormulaRegistry.registerFunction('FORMAT_DATE', Formulas.formatDate)
FormulaRegistry.registerFunction('FORMAT_DURATION', Formulas.formatDuration)
FormulaRegistry.registerFunction('DURATION', Formulas.duration)
FormulaRegistry.registerFunction('UNIX_TIMESTAMP', Formulas.unixSeconds)
FormulaRegistry.registerFunction('UNIX_TIMESTAMP_MS', Formulas.unixMilliseconds)
FormulaRegistry.registerFunction('TIMEZONE_OFFSET', Formulas.timezoneOffset)
FormulaRegistry.registerFunction('NOW_UTC', Formulas.nowUtc) // legacy
FormulaRegistry.registerFunction('DATETIME_TZ', Formulas.nowDateTimeWithTimeZone) // legacy


/* functions */
FormulaRegistry.registerFunction('NEGATE', Formulas.negate)
FormulaRegistry.registerFunction('MEMOIZE', Formulas.memoize, true)
FormulaRegistry.registerFunction('MEMO', Formulas.memoize, true)
FormulaRegistry.registerFunction('CACHE_UNTIL', Formulas.cacheUntil, true)

/* translation */
FormulaRegistry.registerFunction('TRANSLATE', Formulas.translateStringInFormula)
FormulaRegistry.registerFunction('REMOTE_TRANSLATE', Formulas.remoteTranslate)

/* store */
FormulaRegistry.registerFunction('GET_RECORD', Formulas.getRecord)
FormulaRegistry.registerFunction('GET_ENTITY', Formulas.getEntity)
FormulaRegistry.registerFunction('QUERY_RECORDS', Formulas.queryRecords)

/**
 * A simplify version of queryRecords to do ES "query" type
 *
 * @param entityTypeId entity type id
 * @param path path to query against
 * @param value to match
 *
 * @return a list of matched entities
 */
FormulaRegistry.registerFunction('QUERY_RECORDS_BASIC', Formulas.queryRecordsBasic)

/**
 * A simplify version to search across firm
 *
 * @param firmId the firm to include into this search
 * @param entityTypeId entity type to search for
 * @param path entity property to search for
 * @param value  matching value
 */
FormulaRegistry.registerFunction('QUERY_RECORDS_BY_FIRM_BASIC', Formulas.queryRecordsByFirmBasic)

/* utility */
FormulaRegistry.registerFunction('RANGE', Formulas.range)
FormulaRegistry.registerFunction('DEEPLINK_ENTITY_DETAILS', Formulas.deeplinkEntityDetails)
FormulaRegistry.registerFunction('DEEPLINK_WORKFLOW', Formulas.deeplinkWorkflow)
// TODO(Peter): this should probably be a custom function
FormulaRegistry.registerFunction('JOINWEIGHT', Formulas.joinWeight) // legacy
FormulaRegistry.registerFunction('JOINVALUE', Formulas.joinValue) // legacy
FormulaRegistry.registerFunction('PLATFORM', Formulas.getPlatform)
FormulaRegistry.registerFunction('WINDOW_DIMENSIONS', Formulas.getWindowDimensions)

