import _ from 'lodash'
import React from 'react'
import Promise from 'bluebird'

import { browserHistory } from "browser/history"
import { Position, Toast } from 'browser/components/atomic-elements/atoms/toast/toast'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import apis from '../models/apis'
import { Entity } from 'shared-libs/models/entity'
import { EntityFormPanel } from 'browser/components/atomic-elements/organisms/entity/entity-form-panel'

export const enum NavigationPreference {
  KEEP = 'keep',
  REMOVE = 'remove',
}

export const navigateTo = (entityId: string = undefined, viewId: string = NavigationPreference.KEEP, args: any) => {
  if (!entityId && !viewId) {
    return
  }

  let viewPath = ''
  if (viewId === NavigationPreference.REMOVE) {
    // no-op, default value
  } else if (viewId === NavigationPreference.KEEP) {
    const urlSegments = window.location.pathname.split('/').splice(1)

    // this is a hack
    if (urlSegments[2] === 'view') {
      viewPath = `/${urlSegments[0]}/${urlSegments[1]}/${urlSegments[2]}/${urlSegments[3]}`
    } else if (urlSegments[0] === 'view') {
      viewPath = `/${urlSegments[0]}/${urlSegments[1]}`
    }
  } else {
    viewPath = `/view/${viewId}`
  }

  let entityPath = ''
  if (entityId !== undefined) {
    entityPath = `/entity/${entityId}`
  }

  const destinationPath = `${viewPath}${entityPath}`

  return browserHistory.push({
    pathname: destinationPath
  })
}

export const showToast = (message, delay=3000) => {
  const toast = Toast.show({
    message: (
      <div className="c-toastContents">
        <Icon
          className="c-toastContents-icon mr3"
          icon={IconNames.INFO_SIGN}
          size={30}

        />
        <div className="c-toastContents-message">
          {message}
        </div>
      </div>
    ),
    position: Position.BOTTOM_RIGHT,
    isLightMode: true,
  })

  return new Promise((resolve) => {
    setTimeout(() => {
      Toast.dismiss(toast)
      resolve()
    }, delay)
  })
}

function getEntitySchema(entitySchema) {
  if (!entitySchema) {
    return
  }

  const mappedEntity = _.isString(entitySchema) ? apis.getStore().getRecord(entitySchema) : entitySchema
  return (mappedEntity instanceof Entity) ? mappedEntity : new Entity(mappedEntity, apis)
}

export const showEntityCreationModal = ({ defaultValue, schema }) => {
  const settings = apis.getSettings()
  schema = getEntitySchema(schema)

  return EntityFormPanel.openAndAwaitSave({
    defaultValue,
    schema,
    settings,
  })
}

/**
 * Renotify a driver with a new preauthenticated pre-check deeplink, given an
 * identity and context (e.g. cac uniqueId) tuple that matches an existing
 * message record.
 */
export const resendDriverDeeplink = (context: string, identity: string) => {
  if (!context || !identity) {
    console.error(`resendDriverDeeplink missing 'context' or 'identity' args`)
    return
  }
  return apis.resendDeeplink(context, identity)
}
