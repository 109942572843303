import React, { useState, useEffect, Fragment } from 'react'
import _ from 'lodash'

import apis from 'browser/app/models/apis'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'

import { IRbViewHeaderEntitySelectionProps } from './rendition-billing-view-header-buttons'

/**
 * Print the selected rb invoice
 */
export const RbViewHeaderInvoiceDownloadSelected: React.FC<IRbViewHeaderEntitySelectionProps> = (props) => {
  const { selection } = props
  const [ isDownloading, setIsDownloading ] = useState(false)

  if (selection.length <= 1) {
    return null
  }

  const handleDownload = () => {
    const salesInvoices = _.map(selection, (entity) => entity.data)
    setIsDownloading(true)

    return apis
      .salesInvoiceRelatedDocuments(salesInvoices)
      .then((relatedSalesInvoiceDoc) => {
        return _.flatten(relatedSalesInvoiceDoc)
      })
      .then((docs) => {
        const attachments = _.map(docs, (doc: any) => ({
          entityId: doc.get('uniqueId'),
          fileName: `${doc.displayName}.pdf`,
          type: 'document'
        }))

        if (_.isEmpty(attachments)) {
          return Promise.reject(new Error("No attachments"))
        }
        return apis.batchDownloadDocuments({ attachments })
      }).then((url) => {
        window.location.href = url
      }).finally(() => {
        setIsDownloading(false)
      })
  }

  return (
    <Fragment>
      <Button
        className={classNames('mr2', Classes.iconClass(IconNames.DOWNLOAD))}
        isLoading={isDownloading}
        onClick={handleDownload}
      >
        Download Selected
      </Button>
    </Fragment>
  )
}
