import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { ApplicationContext } from '../applicationContext'

const QUERY_WAIT_INTERVAL_MS = 5000

const functionIds = new WeakMap<Function, string>()
const throttledFunctions = new Map<string, Function>()

/* Gets an identifier for a Function */
function getFunctionId(func: Function): string {
  if (!functionIds.has(func)) {
    functionIds.set(func, uuidv4())
  }
  return functionIds.get(func)
}

type Func<T extends any[]> = (...args: T) => any

/* Gets a unique key for a function based on its args */
const getInvocationKey = <T extends any[]>(func: Func<T>, args: T): string => {
  const functionId = getFunctionId(func)
  // assumption: args are serializable (will throw otherwise)
  const serializedArgs = JSON.stringify(args)
  return `${functionId}::${serializedArgs}`
}

/* Throttles a function uniquely based on its args */
const throttle = <T extends any[]>(func: Func<T>, wait: number) => {
  return function (this: ApplicationContext, ...args: T): ReturnType<Func<T>> {
    const key = getInvocationKey(func, args)

    if (!throttledFunctions.has(key)) {
      const throttledFunc = _.throttle((...throttleArgs: T) => func.apply(this, throttleArgs), wait)
      throttledFunctions.set(key, throttledFunc)
    }

    const throttledFunc = throttledFunctions.get(key)
    return throttledFunc(...args)
  }
}

export function getRecord(edgeOrId: { entityId: string } | string) {
  const entityId = _.get(edgeOrId, 'entityId', edgeOrId)

  const context = this as ApplicationContext
  const store = context?.getStore()

  return store.getRecord(entityId)
}

export function getEntity(edgeOrId: { entityId: string } | string) {
  const entityId = _.get(edgeOrId, 'entityId', edgeOrId)

  const context = this as ApplicationContext
  const store = context?.getStore()

  return store.getOrFetchRecord(entityId)
}

/**
 * Elastic search query.
 *
 * @param query standard ES query
 * @param formatResult
 *   true format the raw query result into a list of entities
 *   false just return the raw result without any formatting
 */
async function _queryRecords(query, formatResult = true) {
  const context = this as ApplicationContext
  const store = context?.getStore()

  const rawResult = await store.queryRecords(query)

  return formatResult ? _.map(rawResult?.children, 'data') : rawResult
}

/**
 * A simplify version of queryRecords to do ES "query" type
 *
 * @param entityTypeId entity type id
 * @param path path to query against
 * @param value to match
 *
 * @return a list of matched entities
 */
async function _queryRecordsBasic(entityTypeId: string, path: string, value: any) {
  const context = this as ApplicationContext
  const store = context?.getStore()

  const query = {
    filters: [
      {
        type: 'containsEdge',
        path: 'mixins.active',
        values: [
          {
            entityId: entityTypeId,
          },
        ],
      },
      {
        path,
        type: 'query',
        value,
      },
    ],
  }
  const rawResult = await store.queryRecords(query)

  return _.map(rawResult?.children, 'data')
}

/**
 * A simplify version to search across firm
 *
 * @param firmId the firm to include into this search
 * @param entityTypeId entity type to search for
 * @param path entity property to search for
 * @param value  matching value
 */
async function _queryRecordsByFirmBasic(
  firmId: string,
  entityTypeId: string,
  path: string,
  value: any
) {
  const context = this as ApplicationContext
  const store = context?.getStore()

  const query = {
    filters: [
      {
        type: 'matchFirm',
        value: {
          uniqueId: firmId,
        },
      },
      {
        type: 'containsEdge',
        path: 'mixins.active',
        values: [
          {
            entityId: entityTypeId,
          },
        ],
      },
      {
        path,
        type: 'query',
        value,
      },
    ],
  }
  const rawResult = await store.queryRecords(query)

  return _.map(rawResult?.children, 'data')
}

export const queryRecords = throttle(_queryRecords, QUERY_WAIT_INTERVAL_MS)
export const queryRecordsBasic = throttle(_queryRecordsBasic, QUERY_WAIT_INTERVAL_MS)
export const queryRecordsByFirmBasic = throttle(_queryRecordsByFirmBasic, QUERY_WAIT_INTERVAL_MS)
