import _ from 'lodash'
import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'
import { Classes } from '@blueprintjs/core'
import classNames from 'classnames'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Head } from 'browser/components/atomic-elements/atoms/head/head'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { InputField } from 'browser/components/atomic-elements/molecules/fields/input-field/input-field'
// tslint:disable-next-line:max-line-length
import { AuthenticationPage } from 'browser/components/atomic-elements/organisms/authentication-page/authentication-page'
import { browserHistory } from 'browser/history'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { Entity } from 'shared-libs/models/entity'

interface IPasswordResetProps extends IBaseProps {
  location: any
}

interface IPasswordResetStates {
  confirmPassword: string
  confirmPasswordError: string
  hasInvalidCode?: boolean
  identity?: string
  isLoading: boolean
  isResettingPassword: boolean
  password: string
  passwordError: string
  user?: Entity
}

export class PasswordReset extends React.Component<IPasswordResetProps, IPasswordResetStates> {

  constructor(props) {
    super(props)
    const { location } = this.props
    this.state = {
      confirmPassword: null,
      confirmPasswordError: null,
      identity: _.get(location, 'state.identity'),
      isLoading: false,
      isResettingPassword: false,
      password: null,
      passwordError: null,
      user: null,
    }
  }

  public componentDidMount() {
    const { location } = this.props
    const { code, identity } = queryString.parse(location.search)
    this.setState({ isLoading: true })
    const promise: any = code && identity ?
      apis.postLogin(identity, code) : Promise.resolve()
    promise.then(() => apis.getUser())
      .then((user) => this.setState({ user }))
      .catch(() => this.setState({ hasInvalidCode: true }))
      .finally(() => this.setState({ isLoading: false }))
  }

  public render() {
    if (this.state.isLoading) {
      return <LoadingSpinner />
    }
    return (
      <AuthenticationPage>
        <Head
          title='Reset Password'
        />
        {this.renderContent()}
      </AuthenticationPage>
    )
  }

  private renderContent() {
    const {
      confirmPassword,
      confirmPasswordError,
      hasInvalidCode,
      isResettingPassword,
      password,
      passwordError,
    } = this.state
    const tetherOptions = {
      attachment: 'middle left',
      targetAttachment: 'middle right',
    }
    if (hasInvalidCode) {
      return this.renderInvalidAccessCode()
    }
    return (
      <ComponentsContext.Consumer>
        {({ platform }) => (
          <div className='u-flex u-alignItemsCenter u-justifyContentCenter'>
            <div className='w-100'>
              <h3 className='f3 lh-title mt2 mb0'>
                Enter New Password
              </h3>
              <div className='mb4 mt1'>
                Please enter your new password twice so we can verify you typed it in correctly.
              </div>
              <InputField
                autoFocus={true}
                className='c-formGroup--noBorder c-passwordField'
                data-debug-id='password'
                errorText={passwordError}
                isDisabled={isResettingPassword}
                isHorizontalLayout={platform === PlatformType.WEB}
                onChange={(text) => this.setState({ password: text })}
                onEnterPressed={this.handleResetPassword}
                label='New password'
                density='cozy'
                helpText='Your password must be at least 6 characters long.'
                tabIndex={1}
                size='lg'
                type='password'
                value={password}
              />
              <InputField
                className={classNames('c-formGroup--noBorder c-passwordField', platform === PlatformType.MOBILE_WEB && 'mt3')}
                data-debug-id='password'
                errorText={confirmPasswordError}
                isDisabled={isResettingPassword}
                isHorizontalLayout={platform === PlatformType.WEB}
                onChange={(text) => this.setState({ confirmPassword: text })}
                onEnterPressed={this.handleResetPassword}
                label='Confirm password'
                tabIndex={1}
                size='lg'
                type='password'
                value={confirmPassword}
              />
              <Button
                className={classNames(Classes.INTENT_PRIMARY, Classes.FILL, {
                  'mt3': platform === PlatformType.MOBILE_WEB
                })}
                isLoading={isResettingPassword}
                onClick={this.handleResetPassword}
                size='lg'
              >
                Change my password
              </Button>
            </div>
          </div>
        )}
      </ComponentsContext.Consumer>
    )
  }

  private renderInvalidAccessCode() {
    const { identity } = this.state
    return (
      <div className='u-flex u-alignItemsCenter u-justifyContentCenter'>
        <div className='w-100'>
          <h3 className='f3 b lh-title mt4 mb2 tc'>
            Invalid Account Reset Link
          </h3>
          <div className='lh-copy mb5 mt1 tc'>
            Your account reset link has been used or expired.
          </div>
          <Link
            className={classNames(
              Classes.BUTTON,
              Classes.LARGE,
              Classes.FILL,
              Classes.INTENT_PRIMARY
            )}
            to={{
              pathname: '/sign-in/password-recover',
              search: window.location.search,
              state: { identity },
            }}
            title='Request new reset link'
          >
            Request new reset link
          </Link>
        </div>
      </div>
    )
  }

  private handleResetPassword = () => {
    const { password, confirmPassword, user } = this.state
    const { nextRoute } = _.get(this.props, 'location.state', {})

    this.setState({
      confirmPasswordError: null,
      passwordError: null,
    })

    if (_.isEmpty(password)) {
      return this.setState({
        passwordError: 'Password cannot be empty.',
      })
    } else if (password.length < 6) {
      return this.setState({
        passwordError: 'Password must be at least 6 characters long.',
      })
    } else if (password !== confirmPassword) {
      return this.setState({
        confirmPasswordError: 'Passwords do not match.',
      })
    }

    user.set('user.password', password)

    this.setState({ isResettingPassword: true })
    // TODO(Peter): This is a hack, we have to use saveDraft because we haven't
    // loaded the app bundle, so save will fail when it tries to call validate
    // which requires schemas from app bundle
    user.saveDraft().then(() => {
      browserHistory.push(nextRoute || '/redirect')
    }).catch(() => {
      this.setState({
        isResettingPassword: false,
        passwordError: 'Password must be at least 6 to 32 characters.',
      })
    })
  }
}
