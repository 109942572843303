import _ from 'lodash'
import Cookie from 'js-cookie'
import queryString from 'query-string'
import { browserHistory } from 'browser/history'
import apis from '../models/apis'

export function getAuthorization() {
  const { access_token } = queryString.parse(location.search)
  // access_token might be passed in as query when generating pdf
  return access_token ? `Bearer ${access_token}` : Cookie.get('Authorization')
}

export function signout() {
  browserHistory.push('/sign-in')

  return apis.signout().then((data) => {
    if (_.isEmpty(data)) {
      return
    }

    const redir = data['samlRedirect']

    if (redir) {
      window.location = redir
    }
  })
}

/**
 * Normalizes a url's path components, removing any `access_token` querystring.
 */
export function normalizeRedirectPath(location: Location): string {
  const params = new URLSearchParams(location.search)
  if (params.has('access_token')) {
    params.delete('access_token')
  }
  const joinedParams = params.toString()
  const queryString = joinedParams ? `?${joinedParams}` : ''
  return `${location.pathname}${queryString}${location.hash}`
}
