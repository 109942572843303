import React from 'react'
import _ from 'lodash'
import queryString from 'query-string'
import { Classes } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import { ViewRenderer } from 'shared-libs/components/view/renderer'
import { StoryboardUserEventHandler } from 'shared-libs/models/types/storyboard/storyboard-user-event-handler'
import { EXIT_ROUTE_KEY, WorkflowRoute } from 'shared-libs/models/types/storyboard/storyboard-utils'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { StoryboardNavigator } from 'shared-libs/models/types/storyboard/storyboard-navigator'

import { StoryRenderer } from 'browser/components/atomic-elements/organisms/workflow/story-renderer'
import { VisibilityTimer } from 'browser/app/utils/visibility-timer'
import { StoryboardExecutionStatus, EventType, PATHS, IEventSource } from 'shared-libs/models/types/storyboard/storyboard-execution'
import { ConfirmationModal } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { SceneSelect } from './scene-select'
import { StoryboardStory } from 'shared-libs/models/types/storyboard/storyboard-story'
import { EntityErrorBlock } from 'browser/components/atomic-elements/atoms/error-block/entity-error-block'

import { RequestMethod, Store } from 'shared-libs/models/store'

import 'browser/components/containers/_workflow-manager.scss'
import 'browser/components/containers/_workflow-manager-kiosk.scss'
import { Modal } from '../atomic-elements/atoms/modal'
import apis, { PubSubMessage } from 'browser/app/models/apis'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import classNames from 'classnames'
import { MobileFooter } from 'browser/mobile/components/footer/mobile-footer'
import { Mappings } from 'shared-libs/models/types/storyboard/storyboard-plan-model'
import { LoadingSpinner } from '../atomic-elements/atoms/loading-spinner/loading-spinner'
import { EdgeDetails, Entity } from 'shared-libs/models/entity'
import { followLink, getCurrentGeoPosition, stringToBoolean } from 'browser/app/utils/utils'
import { IWorkflowProgressContextProps, WorkflowProgressContext } from './workflow-task-context'
import { Logger } from 'browser/apis/logging'
import { StoryboardNavigationRoute } from 'shared-libs/models/types/storyboard/storyboard-navigation-route'
import { AJVSchemaValidator } from 'shared-libs/components/entity/ajv-validator'
import { ScrollDownButtonWrapper } from '../atomic-elements/atoms/scroll-down-button-wrapper/scroll-down-button-wrapper'
import { WithMobileHeader } from 'browser/mobile/contexts/mobile-header-context'
import { ChatModal, getUnreadComments } from 'browser/mobile/components/feed/chat-modal'
import { withContext } from 'shared-libs/components/context/with-context'
import { IInboxContext, InboxContext } from 'browser/contexts/inbox/inbox-context'
import { MobileWorkflowEmptyState } from 'browser/mobile/components/workflow/empty-state'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { BlockTransition } from 'browser/components/atomic-elements/atoms/navigation/block-transition'
import { EntitySubscriptionManager } from 'browser/app/utils/entity-subscription-manager'
import { browserHistory } from 'browser/history'
import { Timer } from 'shared-libs/helpers/timer'
import { makeProgressAggregator, ProgressInfo, SaveWithProgressFn } from 'browser/app/utils/progress'
import { DEFAULT_INTERSTITIAL_SYNC_SCHEMA } from './workflow-manager-templates'
import { getSchema } from 'browser/app/utils/workflow'
import { retryOnError } from 'browser/app/utils/retry'
interface ContextProps {
  componentsContext?: IComponentsContext
  inboxContext?: IInboxContext
}

/**
 * @uiComponent
 */
interface PublicProps {
  entity: any
  onClose: () => void
  frames: any
  onWorkflowComplete?: () => any
  onWorkflowCancel?: () => any
}

type IWorkflowManagerProps = PublicProps & ContextProps


interface IWorkflowManagerState {
  uploadProgressInfo?: IWorkflowProgressContextProps
  taskEntity: any
  serverError: any
  entityFailed: any
  navigationState: WorkflowRoute
  isLoading?: boolean
  isProcessing?: boolean
}

export interface IStoryboardTaskInput {
  entityIds?: string[]
  entityId?: string
  preFilledValues?: any
  navigationOptions?: any
  share?: any
}

const SHARED_CONTEXT_REFRESH_INTERVAL_MS = 5 * 1000
const FALLBACK_REFRESH_INTERVAL_MS = 30 * 1000

@withContext(InboxContext, 'inboxContext')
@withContext(ComponentsContext, 'componentsContext')
export class WorkflowManager extends React.Component<IWorkflowManagerProps, IWorkflowManagerState> {
  routeContext: () => any = null
  cancelModalShown = false
  userEventHandler: StoryboardUserEventHandler
  store: any
  nav: StoryboardNavigator
  private edges: string[] = []
  private taskSubscriptions: any = []
  private scrollContainerRef = React.createRef<HTMLDivElement>()

  /**
   * [VD-8524][VD-8318]
   * before an edit session begin, we take snapshot of the entity and its content to prevent
   * its data from being changed during the edit session.  The basic flow is
   *
   * 1. Before the edit session, clone the entity
   * 2. All the modifications during the edit session are done on the clone
   * 3. Once the user clicks 'Save', a diff will be calculated and merge back to original
   * 4. Save the original
   *
   * This way we don't make the change on the original entity itself to avoid the data being mutated
   * by other processes which causes incorrect diff calculation and data loss issue.  Only the actual
   * changes made by the user should be applied
   *
   */
  private editEntity: Entity
  private editEntityContentSnapshot: any

  public async componentDidMount() {
    const { entity: workflowEntity } = this.props
    this.store = this.props.entity.store

    workflowEntity.setJobTagDefault(workflowEntity.uniqueId)
    workflowEntity.core_storyboard_execution.setLocationProvider(this.getGeolocationProvider())

    await workflowEntity.core_storyboard_execution.fetchStoryboardPlan()

    const storyboardPlan = _.get(workflowEntity, 'core_storyboard_execution.storyboardPlan')
    workflowEntity.core_storyboard_execution.updateSharedContext()
    if (_.isNil(workflowEntity.core_storyboard_execution.jobTag)) {
      workflowEntity.core_storyboard_execution.jobTag = workflowEntity.uniqueId
    }

    const user = this.props.entity.api.settings.user
    const queryParams = queryString.parse(location.search)
    // allow both storyId and story_id for convenience
    const storyId = queryParams?.story_id || queryParams?.storyId
    const stories = storyId
      ? storyboardPlan.core_storyboard_plan.getStories(storyId)
      : storyboardPlan.core_storyboard_plan.getStoriesForUser(user, this.props.componentsContext.platform)
    const firstStory = _.head(stories as Array<StoryboardStory>)

    if (!firstStory) {
      return
    }

    this.nav = new StoryboardNavigator(workflowEntity.core_storyboard_execution)
    // todo - handle first story as background task
    this.nav.navigateToStory(firstStory)

    this.userEventHandler = new StoryboardUserEventHandler({
      executionEntity: workflowEntity,
      story: firstStory
    })

    const navigationState = this.nav.currentState
    if (navigationState.task) {
      const context = this.getContext(navigationState.task)
      this.loadTaskEntity(context).then((entity) => {
        this.startTaskSubscriptions(entity, context)
        entity.setJobTag(workflowEntity.uniqueId)
        this.setState({isLoading: false})
      })
    }
    this.setState({
      navigationState,
    })

    this.startPolling().catch((err) => {
      console.warn(`error polling: ${err.message} at ${err.stack}`)
    })
    this.forceUpdate()
  }

  public getContext(task) {
    return this.props.entity.core_storyboard_execution.context.getValueWithMappings(task.data?.inputMappings)
  }

  public componentWillUnmount(): void {
    const updatesEnabled = stringToBoolean(apis.getSettings().getRemoteConfigValue('entityUpdates'))
    if (updatesEnabled) {
      EntitySubscriptionManager.removeEntityUpdateSubscriptions(this.edges)
      EntitySubscriptionManager.removeEntityUpdateListener(this.handlePubsubMessage)
    }
    this.props.entity.stopRefreshPolling()
    this.stopTaskSubscriptions(this.state?.taskEntity)
  }

  public componentDidUpdate(prevProps: IWorkflowManagerProps, prevState: IWorkflowManagerState): void {
    const currentTask = this.state?.navigationState?.task
    const prevTask = prevState?.navigationState?.task
    const { entity: workflowEntity } = this.props

    if (currentTask !== prevTask) {
      this.updateStateTask({
        taskEntity: undefined,
      })

      if (currentTask) {
        const context = this.getContext(currentTask)
        this.loadTaskEntity(context).then((entity) => {
          this.startTaskSubscriptions(entity, context)
          entity.setJobTag(workflowEntity.uniqueId)
          this.setState({ isLoading: false })
        })
      }
    }
  }

  private logWorkflowToAmplitude(prevState: WorkflowRoute, currentState: WorkflowRoute) {
    const storyboard = (navigationState: WorkflowRoute): IEventSource => {
      const taskRoute: StoryboardNavigationRoute = navigationState?.task
      const sceneRoute: StoryboardNavigationRoute = navigationState?.scene
      const story: StoryboardStory = navigationState?.story
      const taskId = taskRoute?.data?.pathId || ''
      const sceneId = sceneRoute?.data?.pathId || ''
      const storyId = story?.data?.id

      return { storyId, taskId, sceneId }
    }

    const prevSource = storyboard(prevState)
    const currentSource = storyboard(currentState)
    const currentRouteId = currentSource.taskId || currentSource.sceneId

    if (!_.isNil(currentRouteId) && ((currentSource.taskId !== prevSource.taskId) || (currentSource.sceneId !== prevSource.sceneId))) {
      Logger.logEvent('Storyboard_Navigation_Next', {
        source: prevSource,
        destination: currentSource,
        entityId: this.props.entity.uniqueId,
      })
    }
  }


  /**
   * Loads an entity for a storyboard task. If it's an edit task, take a snapshot so all edits
   * are made on a cloned copy until the user clicks save. The snapshot is taken before any edits,
   * including prefilled data, to ensure a clean patch for local edits only.
   */
  private loadTaskEntity(context): Promise<Entity | undefined> {
    this.setState({
      isLoading: true,
    })

    return this.props.entity.store.getOrFetchRecord(context.entityId || this.props.entity.uniqueId).then((entity) => {
      if (entity.isSchema) {
        entity = this.createEntity(entity.uniqueId);
      }
      // update the state, this function will also take snapshot of the entity as well
      const taskEntity = this.updateStateTask({ taskEntity: entity })

      if (context && context.preFilledValues) {
        taskEntity?.withDefaultValues(context.preFilledValues)
      }

      return entity
    })
  }

  private useSnapshotOrOriginal = () => {
    const task = this.state?.navigationState?.task
    return _.includes([EventType.EDIT, EventType.DETAIL], _.get(task, 'path.actionType'))
  }

  private updateStateTask(props: any) {
    const { taskEntity } = props
    const shouldSnapshot = this.useSnapshotOrOriginal()
    // note(joco) - we copy the entity for detail tasks, but we don't write it back. this avoids dirtying the entity in the store.

    if (taskEntity && shouldSnapshot) {
      // clone entity to avoid dirtying global state
      this.editEntityContentSnapshot = _.cloneDeep(taskEntity.prevContent)
      this.editEntity = taskEntity.cloneDeep()
    } else {
      this.editEntityContentSnapshot = undefined
      this.editEntity = undefined
    }
    this.setState(props)

    return shouldSnapshot ? this.editEntity : taskEntity
  }

  private async startPolling() {
    const updatesEnabled = stringToBoolean(apis.getSettings().getRemoteConfigValue('entityUpdates'))
    if (updatesEnabled) {
      window.addEventListener('beforeunload', this.componentWillUnmount.bind(this))
      this.subscribeToUpdates()
    }
    const updateInterval = updatesEnabled ? FALLBACK_REFRESH_INTERVAL_MS : SHARED_CONTEXT_REFRESH_INTERVAL_MS
    this.props.entity.startRefreshPolling(VisibilityTimer, updateInterval, this.workflowChanged)
  }

  private async subscribeToUpdates() {
    const { entity } = this.props
    if (!entity) return
    const edges = _.map(entity.getEdges?.(), (edge: EdgeDetails) => edge.value?.entityId)
    edges.push(entity.uniqueId)
    try {
      await EntitySubscriptionManager.diffEntitySubscriptions(this.edges, edges, this.handlePubsubMessage)
    } catch(err) {
      console.log(`failed to update subscriptions: ${err.stack}`)
    }
    this.edges = edges
  }

  private handlePubsubMessage = async (e: PubSubMessage) => {
    const uri = _.get(e, 'data')
    if (!uri) {
      return
    }
    try {
      const parsedUri = new URL(uri)
      if (parsedUri.pathname !== '/actions/entity/update') return
      // Mobile and web seem to present the notification differently
      const json = parsedUri.searchParams.get('jsonProps').replace(/\\/g,'')
      const payload = JSON.parse(json)
      const { entity } = this.props
      await Promise.all([entity.reload(), ..._.map(payload.updatedEntities, async (updated) => {
        if (entity.uniqueId === updated) return Promise.resolve()
        const updatedEntity = apis.getStore().getRecord(updated)
        if (_.isNil(updatedEntity)) {
          return apis.getStore().getOrFetchRecord(updated)
        } else {
          return updatedEntity.reload()
        }
      })])
      this.forceUpdate()
    } catch (err) {
      console.warn(`failed to parse notification: ${err.message}\n${err.stack}`)
    }
  }

  private recordErrorMessage(entity, savePromise) {
    savePromise.then(() => {
      const { serverError, entityFailed } = this.state
      const newState = {}
      if (serverError) newState['serverError'] = undefined
      if (entityFailed) newState['entityFailed'] = undefined
      
      this.setState(newState)
    }).catch((e) => {
      const { code, status, errors, readyState, responseText, statusText } = e
      const message =
        responseText ??
        (statusText === 'error' && readyState === 0 ? 'not connected' : statusText)

      const cleanError = {
        code: code || status,
        errors: errors || { undefined: [message] },
      }

      this.setState({
        serverError: cleanError,
        entityFailed: entity,
      })
    })

    return savePromise
  }

  private workflowChanged = () => {
    const status = _.get(this.props.entity, 'core_storyboard_execution.status')

    if (status !== StoryboardExecutionStatus.CANCELED) {
      this.forceUpdate()
    } else if (!this.props.entity.isCancelledLocally && !this.cancelModalShown) {
      const { onClose, onWorkflowCancel } = this.props
      this.cancelModalShown = true

      ConfirmationModal.open({
        confirmationText: 'This workflow has been cancelled by another user.',
        confirmationTitle: 'Workflow Cancelled',
        modalDialogClassName: 'c-modal-dialog--sm',
        onPrimaryClicked: () => {
          if (onWorkflowCancel) {
            return onWorkflowCancel()
          } else if (onClose) {
            onClose()
          }
        },
        primaryButtonText: 'Continue',
        cancelButtonText: null,
      })
    }
  }


  /**
   * Opt-in feature to monitor new updates for the detail task only, with a configurable pollRate
   */
  private startTaskSubscriptions = (entity: Entity, context: any = {}) => {
    const { pollRate = 0 } = context
    // only monitor for non-execution
    const task = this.state?.navigationState?.task
    if (!entity || entity.isStoryboardExecution || _.get(task, 'path.actionType') !== EventType.DETAIL || pollRate === 0) {
      return
    }
    this.stopTaskSubscriptions(entity)

    this.taskSubscriptions.push(entity.addListener(Store.RECORD_CHANGED, this.taskEntityChanged))

    entity.startRefreshPolling(Timer, Math.max(5000, pollRate))
  }

  private stopTaskSubscriptions = (entity: Entity) => {
    _.forEach(this.taskSubscriptions, (sub) => sub.remove())
    this.taskSubscriptions = []

    if (entity?.isStoryboardExecution === false) {
      entity.stopRefreshPolling()
    }
  }

  private taskEntityChanged = () => {
    this.updateStateTask({
          taskEntity: this.state.taskEntity,
          isLoading: false,
        })

    this.forceUpdate()
  }

  private getActionHooks() {
    return {
      goNext: this.navigateForwards,
      goBack: this.navigateBack,
      openLink: followLink,
      updateExecutionState: this.updateExecutionState,
      // TODO - implement the rest of the hooks, depending which make sense for desktop
    }
  }

  public renderZeroState() {
    if (this.props.componentsContext.platform === PlatformType.MOBILE_WEB) {
      return <MobileWorkflowEmptyState />
    }
    return <div className="u-bumper">You have no pending tasks in this workflow.</div>
  }

  public render() {
    if (this.props.componentsContext.platform === PlatformType.MOBILE_WEB) {
      const { entity, inboxContext } = this.props
      const navigationState = this.state?.navigationState ?? {}
      const { task, scene } = navigationState
      const title = task?.data?.name ?? scene?.data?.name
      const unreadComments = getUnreadComments(entity, inboxContext)
      const chatButtonClass = classNames(Classes.iconClass(IconNames.CHAT), {
        'unread-dot': unreadComments.length > 0,
      })
      return (
        <ScrollDownButtonWrapper>
          <WithMobileHeader
            title={title}
            chatButton={{ handler: this.handleChatClick, className: chatButtonClass }}
            languageButton={{ handler: _.noop }}
            kioskHomeButton={{ handler: this.navigateToKioskHome }}
          >
            {this.renderContentOrEmptyState()}
          </WithMobileHeader>
        </ScrollDownButtonWrapper>

      )
    } else {
      return this.renderContentOrEmptyState()
    }
  }

  private renderContentOrEmptyState() {
    if (_.isEmpty(this.state)) {
      return this.renderZeroState()
    } else {
      return this.renderContentWithProgress()
    }
  }

  private renderContentWithProgress() {
    const { uploadProgressInfo = {}} = this.state
    return (
      <WorkflowProgressContext.Provider value={uploadProgressInfo}>
        {this.renderContent()}
        <BlockTransition
          condition={true}
          onBlockTransition={this.handleBlockTransition}
          debug='workflow-manager'
        />
      </WorkflowProgressContext.Provider>
    )
  }

  private handleBlockTransition = async (onTransition) => {
    const { isProcessing } = this.state

    if (!isProcessing) {
      return onTransition()
    }

    ConfirmationModal.open({
      confirmationText: 'Please wait until processing is complete.',
      confirmationTitle: 'Your tasks are still processing',
      modalDialogClassName: 'c-modal-dialog--sm',
    })
  }

  public renderContent() {
    const { entity } = this.props
    const { serverError, entityFailed } = this.state
    const { navigationState } = this.state
    const currentScene = navigationState?.scene
    const currentTask = navigationState?.task
    const storyboardPlan = _.get(entity, 'core_storyboard_execution.storyboardPlan')
    const isMobileWeb = this.props.componentsContext.platform === PlatformType.MOBILE_WEB

    if (!storyboardPlan || !currentScene) return this.renderZeroState()

    const currentRoute = navigationState.route
    const sceneContext = entity.core_storyboard_execution.context.getValueWithMappings(currentRoute.data?.inputMappings)

    if (!currentRoute || currentRoute.data?.pathId === EXIT_ROUTE_KEY) {
      return this.renderZeroState()
    }

    let taskOrSceneView = null
    const uiSchema = currentScene?.path?.uiSchema(this.props.componentsContext.platform)

    const currentTaskEntity = this.currentTaskEntity()
    if (currentTask) {
      taskOrSceneView = this.renderTask(currentTask, currentTaskEntity)
      this.routeContext = () => currentTaskEntity
    } else if (uiSchema) {
      const settings = this.props.entity.api.settings

      taskOrSceneView = <ViewRenderer
        componentsMap={this.props.componentsContext.components}
        schema={uiSchema}
        state={ {
          settings,
          ...sceneContext,
          self: entity,
          actionHooks: this.getActionHooks(),
          executionEntityId: entity?.uniqueId,
        } }
        api={apis}
        context={isMobileWeb ? {
          density: 'collapse',
          isFullScreen: true,
          isHorizontalLayout: false,
        } : undefined}
      />
      this.routeContext = () => sceneContext
    } else {
      return this.renderZeroState()
    }

    if (entity.isKioskExecution) {
      return this.renderKiosk(taskOrSceneView)
    }

    return (
      <div className={
        classNames('c-workflowManager grid-block vertical', {
          'u-bumperTop': !isMobileWeb,
          'c-workflowManager--mobile': isMobileWeb
        })}
      >
        {!isMobileWeb && this.renderSceneSelectors(storyboardPlan)}

        <div ref={this.scrollContainerRef} className="c-workflowSceneContent grid-block vertical">
          <div className={classNames({'grid-content': !isMobileWeb})}>
            {taskOrSceneView}
          </div>

          { serverError && serverError.errors &&
            <EntityErrorBlock
              entity={entityFailed}
              errors={serverError.errors}
              className="u-bumperBottom"
            />
          }

          {this.renderFooter()}
        </div>
      </div>
    )
  }

  private renderFooter = () => {
    const { entity } = this.props
    const { navigationState, isProcessing } = this.state
    const currentRoute = navigationState.route
    const currentTask = navigationState?.task
    const nextState = this.nav.peekNext(entity.core_storyboard_execution.context)
    const nextRoute = nextState?.route
    const sceneContext = entity.core_storyboard_execution.context.getValueWithMappings(currentRoute.data?.inputMappings)

    // workflow designer must be explicit on exit routes, as entry predicates can cause paths to be unavailable until data has been input
    const hasNext = !nextRoute || nextRoute.data?.pathId !== EXIT_ROUTE_KEY
    const cannotProceed = currentTask && currentTask.actionType === EventType.CREATE
      || this.failsExitConditions()

    const nextButtonText = currentTask?.path?.data?.nextButtonText || currentRoute?.data?.nextButtonText || 'Next'
    const finishButtonText = currentTask?.path?.data?.finishButtonText || currentRoute?.data?.finishButtonText || 'Finish'
    const nextButtonDisabled = currentTask?.path?.data?.nextButtonDisabled || currentRoute?.data?.nextButtonDisabled
    const hideBack = _.get(sceneContext, 'navigationOptions.footerLeft.isHidden', false);
    const hideForward = _.get(sceneContext, 'navigationOptions.footerRight.isHidden', false);

    if (this.props.componentsContext.platform === PlatformType.MOBILE_WEB) {
      if (hideBack && hideForward) {
        return null
      }
      return (
        <MobileFooter
          primaryButtonText={hasNext ? nextButtonText : finishButtonText}
          cancelButtonText="Back"
          isPrimaryButtonDisabled={cannotProceed}
          isVisible={true}
          onCancelButtonClick={(!!this.nav.peekBack() && !hideBack) && this.navigateBack}
          onPrimaryButtonClick={!hideForward && this.navigateForwards}
        />
      )
    } else {
      if (hideBack && hideForward) {
        return null
      }

      return (
        <Footer
          onCancelButtonClick={this.nav.peekBack() && !hideBack && this.navigateBack}
          cancelButtonText='Back'
          isCancelButtonDisabled={isProcessing}
          onPrimaryButtonClick={!nextButtonDisabled && !hideForward && this.navigateForwards}
          isPrimaryButtonDisabled={cannotProceed || isProcessing}
          isPrimaryButtonLoading={isProcessing}
          primaryButtonText={hasNext ? nextButtonText : finishButtonText}
        />
      )
    }
  }

  private failsExitConditions() {
    const { entity } = this.props
    const sharedContext = entity.core_storyboard_execution.context

    if (!this.routeContext) {
      return false
    }

    // todo - refactor predicate logic into shared libs
    const currentRoute = this.state.navigationState.route
    const exitPredicates = _.get(currentRoute, 'data.exitPredicates')

    if (_.isEmpty(exitPredicates)) {
      return false
    }

    return !_.some(exitPredicates, (test) => { return sharedContext.evaluate(test.expression, this.routeContext()) })
  }

  /* add scene-level mappings, if applicable, after task-level mappings */
  private processSceneOutputMappings(navigationState: any, sharedContext: any, execution: any) {
    const node = this.nav.nodeFromState(navigationState)
    const nextState = this.nav.peekNext(sharedContext)
    const nextNode = this.nav.nodeFromState(nextState)
    const isSceneCompleted = _.isNil(nextState?.scene) || node?.scene?.data?.id !== nextNode?.scene?.data?.id
    if (!isSceneCompleted) {
      return
    }
    const mappings = navigationState?.scene?.data?.outputMappings
    if (_.isEmpty(mappings)) {
      return
    }
    const eventOutputMappings = sharedContext.setValueWithMappings(mappings, /* data */ {}) || []
    const event = execution.createEvent(node, 'action')
    event.outputMappings = eventOutputMappings
    execution.addEvent(event)
  }

  private processUserEvents = (currentRoute: any /* TODO: surface IStoryRoute from mobile */) => {
    const { navigationState } = this.state
    const { story, scene, task } = navigationState
    const events = currentRoute?.data?.events ?? []

    if (_.isEmpty(events)) {
      return
    }

    const actionEvents = this.userEventHandler.process(events, {
      story: story,
      scene: scene ? scene.path : null,
      task: task ? task.path : null,
    })

    const { entity } = this.props
    actionEvents.forEach((event) => entity.core_storyboard_execution.addEvent(event))
  }

  private navigateBack = () => {
    this.nav.goBack()
    this.logWorkflowToAmplitude(this.state?.navigationState, this.nav.currentState)

    const navigationState = this.nav.currentState

    this.setState({
      navigationState,
      uploadProgressInfo: undefined,
    })
  }

  private navigateForwards = async (additionalOutputs?: Mappings) => {
    try {
      this.setState({ isProcessing: true })

      await this._navigateForwards(additionalOutputs)

      this.scrollToTop()

      this.setState({ isProcessing: false })
    } catch (e) {
      this.setState({ isProcessing: false })
    }
  }

  private navigateToKioskHome = () => {
    browserHistory.push('/kiosk-search')
  }

  private updateExecutionState = (mappings: Mappings, context = {}) => {
    const { entity: workflowEntity } = this.props
    const execution = workflowEntity.core_storyboard_execution
    const sharedContext = execution.context

    const eventOutputMapping = sharedContext.setValueWithMappings(mappings, context)
    const event = execution.createEvent(this.nav.currentNode, 'action')
    event.outputMappings = eventOutputMapping
    execution.addEvent(event)
  }

  private _navigateForwards = async (additionalOutputs?: Mappings) => {
    if (this.failsExitConditions()) {
      return
    }

    const { entity: workflowEntity } = this.props // TODO - should re-name as workflowEntity on top level state for clarity throughout file
    const { navigationState, taskEntity } = this.state
    const { task } = navigationState
    const execution = workflowEntity.core_storyboard_execution
    const sharedContext = execution.context
    const currentRoute = navigationState.route
    const nextState = this.nav.peekNext(workflowEntity.core_storyboard_execution.context)
    const nextRoute = nextState && (nextState.route)
    const isMobileWeb = this.props.componentsContext.platform === PlatformType.MOBILE_WEB
    // TODO(RMD): remove the hardcode on the pathId before submitting
    const isSyncCheckpoint = isMobileWeb && (currentRoute.data?.isExitSyncCheckpoint || nextRoute.data?.isEntrySyncCheckpoint)
    const workflowSaveProps = { includedPaths: [PATHS.EVENTS] }
    const allTasks: Array<() => Promise<void>> = []

    // aggregate progress across all saves
    const uploadProgressMap: Record<string, ProgressInfo> = {}
    const progressCallback = makeProgressAggregator(uploadProgressMap, this.onProgressThrottled)
    const saveWithProgress = (entity: Entity, props: any, method: RequestMethod, patchContent = null, validate=true) => {
      const uploadId = apis.addPendingJob(entity, null)
      const saveProps = {
        ...props,
        saveTaskId: uploadId,
        onProgress: (progress: number) =>
          progressCallback(uploadId, progress, entity.hasAttachments()),
      }
      return entity.save(saveProps, method, validate, patchContent)
        .finally(() => {
          apis.cancelPendingJob(uploadId)
        })
    }

    const taskType = task?.path?.actionType

    if (_.includes([EventType.CREATE, EventType.EDIT], taskType)) {
      // todo - we shouldn't need special casing like this per task type, in the workflow manager itself
      const eventEntity = taskType === EventType.CREATE ? taskEntity : this.editEntity
      const customValidations = currentRoute?.path?.platformSchema(this.props.componentsContext.platform)?.validationSchema
      await this.runTaskValidations(eventEntity, customValidations)
    }

    // The worker process handles retries, so if we're showing the
    // interstitial we want to return the save promise directly,
    // so `tasks` is accurate.
    const doSaveFn = (method) => saveWithProgress(taskEntity, {}, method, false)
    const retryFn = (method) => isSyncCheckpoint ? doSaveFn(method) : retryOnError(() => doSaveFn(method))

    if (taskType === EventType.EDIT) {
      const jsonDiff = taskEntity.jsonPatch(this.editEntityContentSnapshot, this.editEntity.content, { transformOps: []})
      // run the pre-save actions to capture any generated files like e-signature on save
      await this.editEntity.runPreSaveActions()

      // TODO - patch management belongs in utils / domain logic, not in UI components
      taskEntity.mergeFiles(this.editEntity, true)
      taskEntity.applyPatch(jsonDiff)

      allTasks.push(() => this.recordErrorMessage(
        taskEntity,
        retryOnError(() =>
          saveWithProgress(taskEntity, workflowSaveProps, RequestMethod.PATCH, jsonDiff, false)
        ),
      ).then(() => {
        this.recordEventForCurrentTask(taskEntity)
      }))
    } else if (taskType === EventType.CREATE) {
      // todo - support multi-create, which mobile does in DocumentAdd.tsx
      allTasks.push(() => this.recordErrorMessage(
        taskEntity, 
        retryFn(RequestMethod.PUT),
      ).then(() => {
        this.recordEventForCurrentTask(taskEntity)
      }))
    } else if (taskType === EventType.DETAIL) {
      this.recordEventForCurrentTask(taskEntity)
      this.stopTaskSubscriptions(taskEntity)
    }

    allTasks.push(async () => {
      if (!_.isEmpty(additionalOutputs) && _.isArray(additionalOutputs)) {
        const eventOutputMapping = sharedContext.setValueWithMappings(additionalOutputs)
        const event = execution.createEvent(this.nav.currentNode, 'action')
        event.outputMappings = eventOutputMapping
        execution.addEvent(event)
      }

      // If the user has uploaded an image as part of editing the document above,
      // we need to wait for the backend to reply with an event containing the
      // new URI for the image, and I think a custom trigger also copies the image
      // name or title into the attachments.
      // The proper functioning of goNext() depends on this having happened.
      // ideas to make this faster:
      // 1. send metadata about the image and get the response from the backend,
      //    then queue the image upload for the background
      // 2. Locally apply any event effects instead of waiting for the server.
      this.processUserEvents(navigationState.task)

      this.processSceneOutputMappings(navigationState, sharedContext, execution)
      this.processUserEvents(navigationState.scene)
    })

    allTasks.push(() => this.recordErrorMessage(
      workflowEntity,
      retryOnError(() => saveWithProgress(workflowEntity, workflowSaveProps, RequestMethod.PATCH)),
    ))

    const tasks = _.union(allTasks, apis.pendingJobsForEntityId(workflowEntity?.uniqueId))
    if (!apis.useLegacyUpload && isSyncCheckpoint && (tasks.length > 0) && this.navigateToSyncScene()) {
      return
    } else {
      // Ensure tasks are resolved in order
      // Not catching because we want failures to propagate.
      for (const taskFactory of allTasks) {
        await taskFactory()
      }
    }

    this.nav.goNext(sharedContext)
    this.logWorkflowToAmplitude(this.state?.navigationState, this.nav.currentState)
    await this.recordErrorMessage(
      workflowEntity,
      retryOnError(() => workflowEntity.save(workflowSaveProps, RequestMethod.PATCH)),
    )

    const newCurrentRoute = this.nav.currentState.route
    if (newCurrentRoute?.isImplicitTask()) {
      await this.handleImplicitTask(saveWithProgress)
    }

    await this.recordErrorMessage(
      workflowEntity,
      retryOnError(() => workflowEntity.save(workflowSaveProps, RequestMethod.PATCH)),
    )

    this.setNextTaskState()
  }

  private runTaskValidations = async (entity: Entity, customValidations: any) => {
    const customValidator = customValidations
      ? await AJVSchemaValidator.compile(customValidations)
      : null

    const errors = await entity.validate(true, customValidator)

    if (_.isEmpty(errors)) {
      return
    }

    this.setState({
      serverError: { errors },
      entityFailed: entity,
    })

    throw new Error("Failed to validate entity before save")
  }

  private navigateToSyncScene = () => {
    const { navigationState } = this.state
    const schema = this.interstitialSyncSchema(navigationState)

    if (!schema) {
      return false
    }

    this.setState({
      navigationState: {
        story: navigationState.story,
        scene: { uiSchemaMobileWeb: schema },
        task: undefined,
      } 
    })

    return true
  }

   private interstitialSyncSchema = (source: any) => {
    const plan = this.props.entity.core_storyboard_execution.storyboardPlan

    return getSchema(source, 'uiInterstitialSyncSchemaMobileWeb')
      || plan?.get('core_storyboard_plan.uiView.mobileWeb.interstitialSyncSchema')
      || DEFAULT_INTERSTITIAL_SYNC_SCHEMA
  }


  private onProgressThrottled = _.throttle((uploadProgressInfo: IWorkflowProgressContextProps) => {
    this.setState({ uploadProgressInfo })
  }, 100)

  public recordEventForCurrentTask(taskEntity) {
    const { entity } = this.props
    const execution = entity.core_storyboard_execution

    const newNavigationState = this.nav.currentState
    const { task } = newNavigationState

    const event = execution.createEvent(this.nav.nodeFromState(newNavigationState), task.path.actionType, [taskEntity])
    event.outputMappings = execution.context.setValueWithMappings(task.data?.outputMappings, taskEntity)
    execution.addEvent(event, task.path.actionType)
  }

  public async handleImplicitTask(saveWithProgressFn: SaveWithProgressFn): Promise<void> {
    // todo - warn user if they try and leave the page while saving workflow results
    const { entity } = this.props
    const sharedContext = entity.core_storyboard_execution.context
    const navigationState = this.nav.currentState

    const taskEntity = await this.handleImplicitTaskHelper(saveWithProgressFn)

    this.recordEventForCurrentTask(taskEntity)
    this.processUserEvents(navigationState.task)

    this.processSceneOutputMappings(navigationState, sharedContext, entity.core_storyboard_execution)
    this.processUserEvents(navigationState.scene)

    await retryOnError(() =>
      saveWithProgressFn(entity, { includedPaths: [PATHS.EVENTS] }, RequestMethod.PATCH))

    this.nav.goNext(sharedContext)
    this.logWorkflowToAmplitude(this.state?.navigationState, this.nav.currentState)

    const currentRoute = this.nav.currentState.route
    if (currentRoute?.isImplicitTask()) {
      return await this.handleImplicitTask(saveWithProgressFn)
    }

    return Promise.resolve()
  }

  public async handleImplicitTaskHelper(saveWithProgressFn: SaveWithProgressFn) {
    const entitySnapshots: { [id:string]: any }  = {}
    const { entity } = this.props
    const currentRoute = this.nav.currentState.route
    const sceneContext = entity.core_storyboard_execution.context.getValueWithMappings(
      currentRoute.data?.inputMappings
    )

    const entityIds = _.isEmpty(sceneContext.entityId)
      ? sceneContext.entityIds
      : [sceneContext.entityId]

    const entities = await this.store.getEntities(entityIds)
    const schemas = _.filter(entities, (e) => e.isSchema)

    await this.store.resolveMissingSchemas(schemas, {})

    // take a snapshot before the changes
    const enrichedEntities = _.map(entities, (e: Entity) => {
      if (!e.isSchema) {
        entitySnapshots[e.uniqueId] = _.cloneDeep(e.content)
      }
      return this.enrichEntity(e, sceneContext.preFilledValues)
    })
    await Promise.all(
      _.map(enrichedEntities, (e) => {
        // use PATCH for updates and POST for new entity creation.
        // calculate the patch immediately after an edit to ensure a clean patch.
        // if calculated later in the pipeline or background queue, the patch may include unrelated changes
        // and become quite noisy
        const method = e.isNew ? RequestMethod.POST : RequestMethod.PATCH
        let patch = null
        if (method === RequestMethod.PATCH) {
          const entitySnapshot = _.get(entitySnapshots, e.uniqueId)
          patch = e.jsonPatch(entitySnapshot, e.content)
        }
        return saveWithProgressFn(e, {}, method, patch)
      })
    )

    const firstEntity = enrichedEntities[0]
    return Promise.resolve(firstEntity)
  }

  private enrichEntity(entity: Entity, preFilledValues): Entity {
    const normalizedEntity = entity.isSchema ? this.createEntityForType(entity.uniqueId) : entity

    return normalizedEntity.withDefaultValues(preFilledValues)
  }

  private createEntityForType(entitySchemaId): Entity {
    const metadata = this.store.getRecord(entitySchemaId)

    const newEntity = this.store.createRecord(metadata)
    _.set(newEntity, 'document.source.entityId', this.props.entity.uniqueId)

    return newEntity
  }

  public setNextTaskState = () => {
    const navigationState = this.nav.currentState
    const currentRoute = navigationState.route
    const prevNavigation = this.state.navigationState

    const currentTask = navigationState?.task
    const prevTask = prevNavigation?.task

    /*
     * If the previous task and current task are different, the `componentDidUpdate` function will
     * load a new `taskEntity`. To prevent the use of the new `task` while the new `taskEntity` is being loaded,
     * mark `isLoading` as true.  Failure to do so may result in a situation where `task` and `taskEntity`
     * are out of sync, which can lead to a white screen issue, as the UI schema in `task` may not match
     * that of `taskEntity`.
     */
    this.setState({
      navigationState,
      isLoading: (currentTask !== prevTask),
      uploadProgressInfo: undefined,
    })

    if (!currentRoute || currentRoute.data?.pathId === EXIT_ROUTE_KEY) {
      const { onClose, onWorkflowComplete } = this.props

      // We delay the completion transition to allow the block transition time to disable as isProcessing goes false
      if (onWorkflowComplete) {
        _.delay(onWorkflowComplete, 50)
      } else if (onClose) {
        _.delay(onClose, 50)
      }
    }
  }

  public renderTask(task, taskEntity) {
    const { navigationState } = this.state
    const { frames } = this.props
    const currentRoute = navigationState.route

    if (this.state.isLoading || !taskEntity) {
      return <LoadingSpinner />
    }

    return (
      <StoryRenderer
        task={task.path}
        currentRoute={currentRoute}
        currentNode={this.nav.nodeFromState(navigationState)}
        entity={this.props.entity}
        taskEntity={taskEntity}
        navigateForwards={this.navigateForwards}
        navigateBackwards={this.navigateBack}
        updateExecutionState={this.updateExecutionState}
        onChange={() => {
          this.forceUpdate()
        }}
      />
    )
  }

  private createEntity = (schemaId) => {
    const metadata = this.store.getRecord(schemaId)
    return this.store.createRecord(metadata)
  }

  public renderSceneSelectors(storyboardPlan) {
    const user = this.props.entity.api.settings.user
    const { scene } = this.state.navigationState
    return (
      <SceneSelect
        user={user}
        plan={storyboardPlan.core_storyboard_plan}
        currentScene={scene.getPath()}
        goToStory={this.setStory}
      />
    )
  }

  private currentTaskEntity = () => {
    const { navigationState, taskEntity } = this.state
    const task = navigationState?.task || {}

    const isEdit = _.includes([EventType.EDIT, EventType.DETAIL], _.get(task, 'path.actionType'))
    return isEdit ? this.editEntity : taskEntity
  }

  private setStory = (story: StoryboardStory) => {
    const { entity } = this.props

    this.userEventHandler = new StoryboardUserEventHandler({
      executionEntity: entity,
      story: story
    })

    this.nav.navigateToStory(story)
    this.setState({ navigationState: this.nav.currentState })
  }

  private renderKiosk(taskOrSceneView) {
    return (
      <Modal
        modalStyle={{
          // override media query
          width: '100%',
          height: '100%',
          padding: 0,
        }}
        modalPaperClassName="h-100 overflow-hidden c-workflowKioskModal"
      >
        {taskOrSceneView}
      </Modal>
    )
  }

  private handleChatClick = () => {
    ChatModal.open(
      {
        entity: this.props.entity,
      },
      this.props.entity.api.settings,
      this.props.componentsContext,
      this.props.inboxContext
    )
  }

  private getGeolocationProvider = () => {
    const { componentsContext } = this.props

    if (componentsContext.platform !== PlatformType.MOBILE_WEB) {
      // only mobile-web will provide location data, so drivers' distance from
      // the facility stands out more clearly to web users.
      return undefined
    }

    return async () => {
      const position = await getCurrentGeoPosition()
      const coords = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      return { geolocation: coords }
    }
  }

  private scrollToTop() {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollTop = 0
    }
  }
}
