import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'

import apis from 'browser/app/models/apis'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { ISelectProps, Select } from 'browser/components/atomic-elements/atoms/select'
import { getAddressFromGooglePlace } from 'shared-libs/helpers/google-places'
import { Query } from 'shared-libs/models/query'
import { Place } from '../interface'
import { DEBOUNCE_TIMEOUT } from 'browser/app/utils/utils'

export interface ICitySelectProps extends Partial<ISelectProps> {
  onChange: (value: object) => void
  onLoadOptions?: (query: string) => Promise<any>
  optionLabelPath?: string
  optionRenderer?: (option: any) => React.ReactElement<any>
  optionValuePath?: string
  placeholder?: string
  value?: object
}

interface ICitySelectState {
  isLoading: boolean
  options: any[]
}

export class CitySelect extends React.Component<ICitySelectProps, ICitySelectState> {

  public static defaultProps: Partial<ICitySelectProps> = {
    optionLabelPath: 'description',
    optionValuePath: 'place_id',
  }

  private lastQuery: any

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      options: [],
    }
    this.handleQueryChange = _.debounce(this.handleQueryChange, DEBOUNCE_TIMEOUT)
  }

  public componentWillUnmount() {
    this.lastQuery?.cancel()
  }

  public render() {
    const {
      optionLabelPath,
      optionRenderer,
      optionValuePath,
      placeholder,
      value,
    } = this.props
    const {
      isLoading,
      options,
    } = this.state
    return (
      <Select
        {... this.props as ISelectProps}
        isAsync={true}
        isLoading={isLoading}
        onChange={this.handleChange}
        onInputChange={this.handleQueryChange}
        options={options}
        optionLabelPath={optionLabelPath}
        optionRenderer={optionRenderer}
        optionValuePath={optionValuePath}
        placeholder={placeholder}
        value={value}
      />
    )
  }

  private handleChange = (placeId, option) => {
    if (!placeId) {
      this.props.onChange(undefined)
      return
    }
    apis.getGoogleMapsPlace(placeId).then((json) => {
      const address = getAddressFromGooglePlace(json[0])
      this.props.onChange(address)
    })
  }

  private handleQueryChange = (input) => {
    const query = input ? input.trim() : null
    if (_.isEmpty(query)) {
      return
    }
    this.setState({ isLoading: true })
    this.lastQuery = this.loadOptions(query)
    this.lastQuery.then((options: any[]) => {
      this.lastQuery = null
      this.setState({
        isLoading: false,
        options: this.patchCitiesWithCounts(options)
       })
    })
  }

  private loadOptions = (query) => {
    this.lastQuery?.cancel()

    const { onLoadOptions } = this.props
    if (onLoadOptions) {
      return onLoadOptions(query)
    }
    return apis.getGoogleMapsPlacesAutomComplete(query, {
      types: ['(cities)'],
    })
  }

  private patchCitiesWithCounts = (cities: Place[]) => {
    return _.map(cities, (city) => ({
      ...city,
      countDisplay: `${city.displayName} (${city.numLoads})`
    }))
  }
}
