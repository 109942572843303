import _ from "lodash"
import { v4 as uuidv4 } from 'uuid'

import { CustomFormulas } from "shared-libs/helpers/formulas"
import { EntityProxy } from "shared-libs/models/proxy"

import apis from "browser/app/models/apis"
import { ActionInputModal } from "browser/components/atomic-elements/organisms/action-input-modal"
import { evaluateExpression } from "shared-libs/helpers/evaluation"
import { IComponentsContext } from "browser/contexts/components/components-context"
import { Toast } from "browser/components/atomic-elements/atoms/toast/toast"
import { rendererActions } from "shared-libs/components/renderer-actions"

enum ContextType {
  BULK = 'bulk',
  SINGLE = 'single',
}

interface Mapping {
  destination: string
  value: string
  formula: string // formula
  shouldApply: boolean
}

export class Action extends EntityProxy {
  title: string
  contexts: ContextType[]
  isHamburger: boolean
  iconClass: string
  entitiesToEdit: string // formula
  skipValidation: boolean
  outputMappings: Mapping[]
  confirmationTitle: string
  confirmationText: string
  completionToastText: string
  showCommentPreview: boolean

  requestComment: boolean
  commentTemplates: string[] // interpolatable strings
  variableMappings: { property: string} // LHS key, RHS getter value path

  onRefresh: () => void

  componentsContext: IComponentsContext

  editSchemaPath: string

  __reactKey: string // simplest way to appease the React render gods

  constructor(data, onRefresh=undefined) {
    super(data)
    this.registerOwnProperties()
    this.__reactKey = uuidv4()
    this.onRefresh = onRefresh
  }

  public get isBulk() {
    return _.includes(this.contexts, ContextType.BULK)
  }

  public get isSingle() {
    return _.includes(this.contexts, ContextType.SINGLE)
  }

  public getEvaluator(entity) {
    const { variableMappings } = this
    const settings = entity.getSettings()
    const ctx = {
      entity,
      settings,
    }
    const mappedVariables = _.mapValues(variableMappings, keyValue => evaluateExpression(ctx, keyValue))

    return template => _.template(template)(mappedVariables)
  }

  public process = async (entities) => {
    const {
      confirmationTitle,
      confirmationText,
      completionToastText,
      requestComment,
      commentTemplates,
      variableMappings,
      editSchemaPath,
      componentsContext,
      showCommentPreview,
    } = this

    const sampleEvaluator = this.getEvaluator(entities[0])

    const _process = async (commentValue?: string, diffToApply?: any) => {
      try {
        await this._process(entities, commentValue, diffToApply)
        if (completionToastText) {
          const completionToast = _.template(completionToastText)({ entities })
          Toast.show({ message: completionToast, timeout: 3000, isLightMode: true })
        }
      } catch (e) {
        console.error('Failed to process action', e)
        Toast.show({ message: 'Failed to process action', timeout: 3000, isLightMode: true })
      }
    }

    if (confirmationTitle || confirmationText) {
      ActionInputModal.open({
        confirmationTitle,
        confirmationText,
        onConfirm: _process,

        requestComment,
        commentTemplates,
        variableMappings,
        sampleEvaluator,
        showCommentPreview,

        componentsContext,
        editSchemaPath,
        sampleEntity: entities[0],
      })
    } else {
      return _process()
    }
  }

  public _process = async (entities, commentValue, diffToApply) => {
    const processingPromise = Promise.all(entities.map(async entity => {
      const entitiesToEdit = this.entitiesToEdit ?
        await apis.getStore().findRecords(this.eval({ entity }, this.entitiesToEdit)) :
        [entity]

      return Promise.all(entitiesToEdit.map(async nestedEntity => {
        await Promise.all((this.outputMappings || []).map(async m => {
          const shouldApply = m.shouldApply ? this.eval({ nestedEntity }, m.shouldApply) : true

          if (!shouldApply) {
            return
          }

          const value = m.formula ? await this.eval({ entity: nestedEntity }, m.formula) : m.value

          if (!m.destination) {
            return
          }
          nestedEntity.set(m.destination, value)
        }))

        if (!_.isEmpty(diffToApply)) {
          nestedEntity.applyPatch(diffToApply)
        }
        const promises = [nestedEntity.save({ skipValidation: this.skipValidation })]

        if (commentValue) {
          const templatedComment = this.getEvaluator(nestedEntity)(commentValue)
          promises.push(nestedEntity.addComment(templatedComment))
        }

        return Promise.all(promises)
      }))
    }))

    await processingPromise

    // Why is ES so slow to re-index when filter values change???
    this.onRefresh && _.delay(this.onRefresh, 3000)
  }

  public eval(additionalProps, formula) {
    const settings = apis.getSettings()

    return evaluateExpression(
      {
        ...rendererActions.getAll(),
        settings,
        ...CustomFormulas, 
        ...additionalProps
      },
      formula
    )
  }
}
