import { Promise as BPromise } from 'bluebird'
import _ from 'lodash'

const ERROR_PROCESSING = 423
const ERROR_GENERIC = 400 // RequestSink.java has 2 codes for "concurrent modification detected" - 400 and 409...
const CONCURRENT_MODIFICATION = "Concurrent modification detected, please retry"
const TEMPORARY_ERROR_CODES = [ERROR_PROCESSING]

export function retryOnError(promiseFn) {
  const maxRetries = 10
  let retries = 0

  return new BPromise((resolve, reject) => {
    const retryFunction = () => {
      promiseFn().then((entity) => {
        resolve(entity)
      }).catch((e) => {
        if (retries >= maxRetries || !isRetryable(e)) {
          return reject(e)
        }

        retries += 1
        BPromise.delay(500 * retries).then(retryFunction)
      })
    }

    retryFunction()
  })
}

export function isRetryable(error: any): boolean {
  const code = error.status || error.code

  const isTemporary = _.includes(TEMPORARY_ERROR_CODES, code)
  const isConcurrentModification =
    error.message === CONCURRENT_MODIFICATION ||
    error.errors?.modifiedDate?.includes(CONCURRENT_MODIFICATION)

  return isTemporary || (code === ERROR_GENERIC && isConcurrentModification)
}
